/**
 * Active User Store
 * This is the store for the logged in user
 */
import { defineStore } from 'pinia'
import { useRouter } from 'vue-router'
import { useSentry } from '../composables/sentry'
import { AdditionalRole } from '../constants/additionalRoles'
import { UserRole } from '../constants/userRoles'
import AuthService from '../services/AuthService'
import { type User, toUser } from '../services/UserInterfaces'
import { usePricingParamStore } from '../stores/PricingParamStore'
import { userAvatar } from '../utils/userAvatar'
import { userFullName } from '../utils/userFullName'

const s = useSentry()

interface ActiveUserStore {
  user: User
  auth: any
  permissions: Array<any>
  customClaims: Array<string>
  providerData: any
}

export const useActiveUserStore = defineStore('ActiveUserStore', {
  state: (): ActiveUserStore => ({
    user: {
      ...toUser()
      // customClaims:
    },
    auth: {
      uid: null
    },
    permissions: [],
    customClaims: [],
    providerData: {}
  }),
  getters: {
    isLoggedIn: state => !!state.auth.uid,
    avatar: state => userAvatar(state.user.email),
    isAdmin: (state) => {
      const adminRoles: string[] = ['admin', UserRole.PowerUser]
      return state.customClaims.some(i => adminRoles.includes(i))
    },
    isDevTeam: (state) => {
      const roles: string[] = [AdditionalRole.DevTeam]
      return state.customClaims.some(i => roles.includes(i))
    },
    // Dev Team is temporary until we are sure this works as needed for finance and there is enough coverage for related tasks
    isFinanceAdmin: (state) => {
      const roles: string[] = [UserRole.FinanceAdmin, AdditionalRole.DevTeam]
      return state.customClaims.some(i => roles.includes(i))
    },
    isModelMaintainer: (state) => {
      const roles: string[] = [AdditionalRole.DevTeam, UserRole.ModelMaintainer]
      return state.customClaims.some(i => roles.includes(i))
    },
    canUnlockQuote: (state) => {
      const roles: string[] = [AdditionalRole.DevTeam, AdditionalRole.CanUnlockQuote]
      return state.customClaims.some(i => roles.includes(i))
    },
    userMenuItems(state) {
      const router = useRouter()
      const menuItems = [
        {
          label: 'User Profile',
          command: () => router.push(`/users/${state.auth.uid}`)
        },
        {
          label: 'Sign Out',
          command: () => {
            AuthService.signOutFirebase()
            router.push('/login')
          }
        }
      ]
      if (this.isDevTeam) {
        menuItems.splice(1, 0, {
          label: 'Dev Team Area',
          command: () => router.push('/dev-team')
        })
      }
      return menuItems
    },
    fullName(state) {
      return userFullName(state?.user?.firstName, state?.user?.lastName)
    }
  },
  actions: {
    setUser(payload: any) {
      const {
        userAuthRecord,
        userDatabaseRecord,
        permissions,
        customClaims,
        providers
      } = payload

      try {
        this.auth = { ...userAuthRecord } || { uid: null }
        this.user = { ...userDatabaseRecord }
        this.permissions = [].concat(permissions)
        this.customClaims = [].concat(customClaims)
        this.providerData = { ...providers }
      }
      catch (error: any) {
        s.handleError(error)
      }
      finally {
        this.loadPricing()
      }
    },
    signOutUser() {
      this.auth = { uid: null }
      this.user = { ...toUser() }
      this.permissions = []
      this.customClaims = []
      this.providerData = {}
      // TODO : delete session data
    },
    async loadPricing() {
      await this.loadPricingBrackets()
      await this.loadCostMultipliers()
      await this.loadMcePriceBrackets()
    },
    async loadPricingBrackets() {
      const { getPricingBracketFromDatabaseGetAll } = usePricingParamStore()
      await getPricingBracketFromDatabaseGetAll()
    },
    async loadCostMultipliers() {
      const { getCostMultipliers } = usePricingParamStore()
      await getCostMultipliers()
    },
    async loadMcePriceBrackets() {
      const { getMcePriceBrackets } = usePricingParamStore()
      await getMcePriceBrackets()
    }
  }
})
