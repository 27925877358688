import type { ObjectKeys } from '../interfaces/ObjectKeys'

export enum UserRole {
  Employee = 'employee',
  PowerUser = 'power-user',
  Customer = 'customer',
  FinanceAdmin = 'finance-admin',
  ModelMaintainer = 'model-maintainer'
}

export const userRoleConfigs: ObjectKeys = {
  employee: {
    chipClass: 'bg-primary-700 text-white'
  },
  'power-user': {
    chipClass: 'bg-yellow-500 text-black'
  },
  customer: {
    chipClass: 'bg-green-100 text-green-700'
  },
  'finance-admin': {
    chipClass: 'bg-purple-100 text-purple-600'
  },
  'model-maintainer': {
    chipClass: 'bg-primary-100 text-primary-700'
  },
  default: {
    chipClass: ''
  }
}

export const userRoles = Object.keys(userRoleConfigs)

export const getUserRoleConfig = (key: string) => {
  if (!userRoles.includes(key))
    return userRoleConfigs.default
  return userRoleConfigs[key]
}
