/**
 * @param {any} payload
 * @param {string} payload.includes Text to look for in session storage. If blank then it will clear all Dasco Session Storage
 */
export const clearSessionStorage = (payload: any = {}) => {
  const {
    includes = 'dasco'
  } = payload
  const keys = Object
    .keys(sessionStorage)
    .filter((i: string) => i.includes(includes))
  keys.forEach((i: string) => sessionStorage.removeItem(i))
}
