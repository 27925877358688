import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useCustomerClassStore = defineStore('CustomerClassStore', () => {
  const defaultCustomerClass = '99'
  const customerClass = ref(defaultCustomerClass)

  function updateCustomerClass(cc = defaultCustomerClass) {
    const _cc = cc.toString()
    customerClass.value = _cc
  }

  return {
    customerClass,
    defaultCustomerClass,
    updateCustomerClass
  }
})
