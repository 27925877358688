/**
 * Calculate all lines of a document
 * Typically used for quotes
 *
 * `blanket_quote` defaults to false and must be passed in to set all pricing to customer class only with no quantity discounts
 */
import { cloneDeep } from 'lodash-es'
import { useSentry } from '../composables/sentry'
import { ItemType } from '../constants/itemTypes'
import { lineItemScope, type lineItemScopeCodesArray } from '../constants/lineItemScope'
import { priceDefault } from '../constants/priceDefault'
import type { ObjectKeys } from '../interfaces/ObjectKeys'
import type { ResponseSimple } from '../interfaces/Response'
import { getPcPgKey } from '../utils/getPcPgKey'
import { calculateItemListPrice } from './PricingServiceCalculateItemListPrice'
import { calculateItemListPriceNonConfigured } from './PricingServiceCalculateItemListPriceNonConfigured'
import { calculateItemSellPrice } from './PricingServiceCalculateItemSellPrice'
import { calculateItemSellPriceNonConfigured } from './PricingServiceCalculateItemSellPriceNonConfigured'

const s = useSentry()

interface ItemPricing {
  [key: string]: {
    line_item_scope: typeof lineItemScopeCodesArray
    pcpg: string
    pcpgTotal: number
    pricingList: {
      single: number | null
      subtotal: number | null
    }
    pricingSell: {
      single: number | null
      subtotal: number | null
    }
    qty: number
  }
}

/**
 * PcPg Totals are all items that
 * - Share a pcpg key
 * - Do NOT have a price override
 * - If Blanket Pricing then set to 1
 * - Are NOT exclude from total value of the doc
 *    - For example, you can add a suggested substitution or add-on for the customer to see but should not impact the value of the quote
 */
const getPcPgTotals = async ({ items, blanket_quote = false }: { items: any, blanket_quote: boolean }): Promise<any> => {
  const totals: ObjectKeys = {}
  items.forEach((i: any) => {
    if (i.item_type !== ItemType.Configured)
      return

    const pcpg = getPcPgKey(i.product_class_code, i.product_group_code)
    if (!i.price_override) {
      if (!Object.hasOwn(totals, pcpg))
        totals[pcpg] = blanket_quote ? 1 : +i.qty
      else
        totals[pcpg] = blanket_quote ? 1 : totals[pcpg] + +i.qty
    }
  })
  return totals
}

const calculateLineItems = async (payload: any): Promise<ResponseSimple> => {
  const {
    blanket_quote = false,
    items,
    pricingBrackets
  } = payload

  const pricing: ItemPricing = {}
  try {
    const itemsProducts = [...cloneDeep(items)].filter((i: any) => !['section-heading', 'note'].includes(i.type))
    if (!itemsProducts) {
      return {
        success: true,
        data: pricing
      }
    }

    const pcpgTotals = await getPcPgTotals({ items: itemsProducts, blanket_quote })
    const defaultPricing = { single: priceDefault, subtotal: priceDefault }

    itemsProducts.forEach((i: any) => {
      const pcpgKey = getPcPgKey(i?.product_class_code, i?.product_group_code)

      if (i.item_type === ItemType.Configured) {
        pricing[i.id] = {
          line_item_scope: i.line_item_scope || lineItemScope.include.code,
          pcpg: pcpgKey,
          pcpgTotal: pcpgTotals[pcpgKey],
          pricingList: calculateItemListPrice({ item: i })?.data || defaultPricing,
          pricingSell: calculateItemSellPrice({
            item: i,
            pricingBracket: pricingBrackets[pcpgKey],
            qty: pcpgTotals[pcpgKey]
          })?.data || defaultPricing,
          qty: i.qty
        }
      }
      else {
        /**
         * Currently all non configured until use case comes up.
         * Should even allow the quoting of component and non-inventory items
         */
        pricing[i.id] = {
          line_item_scope: i.line_item_scope || lineItemScope.include.code,
          pcpg: pcpgKey,
          pcpgTotal: 1,
          pricingList: calculateItemListPriceNonConfigured({ item: i })?.data || defaultPricing,
          pricingSell: calculateItemSellPriceNonConfigured({ item: i })?.data || defaultPricing,
          qty: i.qty
        }
      }
    })
    return {
      success: true,
      data: pricing
    }
  }
  catch (error: any) {
    s.handleError(error)
    return {
      success: false,
      data: pricing
    }
  }
}

const calculateSubtotal = async (payload: any): Promise<ResponseSimple> => {
  const {
    items,
    type
  } = payload
  try {
    const prop = type === 'list' ? 'pricingList' : 'pricingSell'

    const subtotals: number[] = Object
      .values(items)
      .filter((i: any) => !i.line_item_scope.includes('exclude')) // keep only line items that add value to the doc
      .map((i: any) => i[prop].subtotal)

    let subtotal

    if (subtotals.includes(priceDefault)) {
      subtotal = priceDefault
    }
    else {
      subtotal = subtotals.reduce<number>((a, b) => {
        return a + b
      }, 0)
    }
    return {
      success: true,
      data: subtotal
    }
  }
  catch (error: any) {
    s.handleError(error)
    return {
      success: false
    }
  }
}

export const calculateDocPricing = async (payload: any): Promise<ResponseSimple> => {
  try {
    const lineItems = await calculateLineItems(payload)
    const subtotalList = await calculateSubtotal({ items: lineItems.data, type: 'list' })
    const subtotalSell = await calculateSubtotal({ items: lineItems.data, type: 'sell' })
    return {
      success: true,
      data: {
        items: lineItems.data,
        subtotal_list: subtotalList.data || priceDefault,
        subtotal_sell: subtotalSell.data || priceDefault
      }
    }
  }
  catch (error: any) {
    s.handleError(error)
    return {
      success: false,
      data: {
        item: {},
        subtotal_list: null,
        subtotal_sell: null
      }
    }
  }
}
