<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { ToastGroup } from '../constants/toastGroup'

const router = useRouter()
const toast = useToast()
</script>

<template>
  <p-toast
    :group="ToastGroup.BuildIssueConfiguredItem"
    position="bottom-right"
    :style="{ 'min-width': '35rem', 'opacity': 1 }"
  >
    <template #message="{ message }">
      <div class="flex gap-3 p-0">
        <i class="fa fa-triangle-exclamation text-4xl text-yellow-400" />
        <div class="flex flex-column gap-3">
          <h1 class="m-0 font-semibold text-xl text-red-700">
            {{ message.summary }}
          </h1>
          <div
            class="text-base text-700"
            v-html="message.detail"
          />
          <div class="flex gap-3">
            <p-btn
              label="Items List"
              icon="fa fa-chevron-left"
              class="p-button-text p-button-sm px-2 py-1"
              @click="router.push('/items'); toast.removeGroup(ToastGroup.BuildIssueConfiguredItem)"
            />
            <!-- TODO -->
            <!-- <p-btn
              label="Report Issue"
              class="p-button-text p-button-warning p-button-sm px-2 py-1"
              disabled
              icon="fa fa-triangle-exclamation"
              @click="toast.removeGroup(ToastGroup.BuildIssueConfiguredItem)"
            /> -->
            <p-btn
              label="Close"
              class="p-button-text p-button-plain p-button-sm px-2 py-1"
              @click="toast.removeGroup(ToastGroup.BuildIssueConfiguredItem)"
            />
          </div>
        </div>
      </div>
    </template>
  </p-toast>
</template>
