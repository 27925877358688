export default [
  {
    path: '/addresses',
    name: 'AddressesList',
    component: () => import('../views/AddressesList.vue')
  },
  {
    path: '/addresses/:id',
    name: 'AddressSingle',
    component: () => import('../views/AddressSingle.vue')
  }
]
