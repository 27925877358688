const storeToUse = 'user'

export default [
  {
    path: '/users',
    name: 'users-list',
    component: () => import('../views/UsersList.vue')
  },
  {
    path: '/users/:id',
    name: 'user-single',
    component: () => import('../views/UserSingleWrapper.vue'),
    redirect: { name: 'user-single-home' },
    children: [
      {
        path: '',
        name: 'user-single-home',
        component: () => import('../components/UserSingleHome.vue')
      },
      {
        path: 'account-info',
        name: 'user-single-account',
        component: () => import('../components/UserSingleAccountInfo.vue')
      },
      {
        path: 'history',
        name: 'user-single-history',
        component: () => import('../components/DocSingleHistory.vue'),
        props: { storeToUse }
      },
      {
        path: 'notes',
        name: 'UserSingleNotes',
        component: () => import('../components/DocSingleNotes.vue'),
        props: { storeToUse, isPrivate: false }
      },
      {
        path: 'notes-internal',
        name: 'UserSingleNotesInternal',
        component: () => import('../components/DocSingleNotes.vue'),
        props: { storeToUse, isPrivate: true }
      },
      {
        path: 'orders',
        name: 'UserSingleOrders',
        component: () => import('../components/UserSingleOrders.vue')
      },
      {
        path: 'personal-info',
        name: 'user-single-personal-info',
        component: () => import('../components/UserSinglePersonalInfo.vue')
      },
      {
        path: 'raw-data',
        name: 'user-single-raw-data',
        component: () => import('../components/DocSingleRawData.vue'),
        props: { storeToUse }
      },
      {
        path: 'user-maintenance',
        name: 'user-single-user-maintenance',
        component: () => import('../components/UserSingleUserMaintenance.vue')
      }
    ]
  }
]
