import { useSentry } from '../composables/sentry'
import { priceDefault } from '../constants/priceDefault'
import type { ResponseSimple } from '../interfaces/Response'
import { checkContactForPricing } from './PricingServiceCheckContactForPricing'
import { parseDecimal } from './PricingServiceParseDecimal'
import { sumPriceTotal } from './PricingServiceSumPriceTotal'

const s = useSentry()

interface Payload {
  item: any
}

export const calculateItemListPrice = (payload: Payload): ResponseSimple => {
  const {
    item
  } = payload
  try {
    if (!item)
      throw new Error('Error: Item required')
    // Check if pricing has priceDefault === 9999.99
    const contact = checkContactForPricing({ configuration: item?.configuration })
    if (contact || item?.list_price_base === priceDefault) {
      return {
        success: true,
        data: {
          single: priceDefault,
          subtotal: priceDefault,
        }
      }
    }

    const listPrice = sumPriceTotal({ item })

    if (listPrice === priceDefault) {
      return {
        success: true,
        data: {
          single: priceDefault,
          subtotal: priceDefault
        }
      }
    }
    return {
      success: true,
      data: {
        single: listPrice !== priceDefault ? parseDecimal(listPrice) : priceDefault,
        subtotal: (item.qty && listPrice !== priceDefault) ? parseDecimal(parseDecimal(listPrice) * item.qty) : priceDefault
      }
    }
  }
  catch (error: any) {
    s.handleError(error)
    return {
      success: false,
      data: {
        single: priceDefault,
        subtotal: priceDefault
      }
    }
  }
}
