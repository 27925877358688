import { createRouter, createWebHistory } from 'vue-router'
import { getAuth, isSignInWithEmailLink } from 'firebase/auth'
import { setUser as setUserSentry } from '@sentry/vue'
import { app } from '../services/FirebaseService'
import AuthService from '../services/AuthService'
import { useActiveUserStore } from '../stores/ActiveUserStore'
import App from '../App.vue'
import AccountRoutes from './AccountRoutes'
import AdminRoutes from './AdminRoutes'
import AddressRoutes from './AddressRoutes'
import BaseModelEditorRoutes from './BaseModelEditorRoutes'
import BaseModelRoutes from './BaseModelRoutes'
import ConfiguratorEditorRoutes from './ConfiguratorEditorRoutes'
import ConfiguratorRoutes from './ConfiguratorRoutes'
import DevRoutes from './DevRoutes'
import ItemRoutes from './ItemRoutes'
import PriceSheetsRoutes from './PriceSheetsRoutes'
import QuoteRoutes from './QuoteRoutes'
import QuoteEditRoutes from './QuoteEditRoutes'
import UserRoutes from './UserRoutes'
import ThermowellRoutes from './ThermowellRoutes'

const routes = [
  {
    path: '/',
    name: 'app',
    component: App,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../views/Home.vue')
      },
      {
        // may need to add this outside of children as well when the nav guards are in place for auth
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/NotFound.vue')
      },
      ...AccountRoutes,
      ...AdminRoutes,
      ...AddressRoutes,
      ...BaseModelEditorRoutes,
      ...BaseModelRoutes,
      ...ConfiguratorEditorRoutes,
      ...ConfiguratorRoutes,
      ...DevRoutes,
      ...ItemRoutes,
      ...PriceSheetsRoutes,
      ...QuoteRoutes,
      ...QuoteEditRoutes,
      ...ThermowellRoutes,
      ...UserRoutes,
      {
        path: '/loaded-pricing-params',
        name: 'LoadedPricingParams',
        component: () => import('../views/LoadedPricingParamsPreview.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

type LoginLinkState = 'not-login-link' | 'login-link-not-attempted' | 'login-link-used'
let loginLinkState: LoginLinkState = 'not-login-link'

router.beforeEach(async (to) => {
  // Intercept flow if url is a sign link from email
  const auth = getAuth(app)
  const isLink = isSignInWithEmailLink(auth, window.location.href)
  const activeUserStore = useActiveUserStore()

  if (isLink && loginLinkState !== 'login-link-used')
    loginLinkState = 'login-link-not-attempted'

  if (isLink && loginLinkState === 'login-link-not-attempted' && !activeUserStore.isLoggedIn) {
    await handleSignInLink({ activeUserStore })
    return {
      path: '/login',
      query: {}
    }
  }

  if (!activeUserStore.isLoggedIn && to.name !== 'Login') {
    let next = {}
    if (to.fullPath === '/') {
      next = {
        path: '/login'
      }
    }
    else {
      next = {
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      }
    }
    return next
  }
  else if (to.meta.requiresAdmin && !activeUserStore.isAdmin) {
    return false
  }
  // TODO: Add blocks for dev team routes
})

async function handleSignInLink({ activeUserStore }: { activeUserStore: any }) {
  const res = await AuthService.signInWithLink(window.location.href)

  loginLinkState = 'login-link-used'

  if (!res.success) {
    return {
      success: false
    }
  }
  activeUserStore.setUser({
    userAuthRecord: res.userAuthRecord,
    userDatabaseRecord: {
      uid: res.userAuthRecord.uid,
      ...res.userDatabaseRecord
    },
    permissions: res.permissions,
    customClaims: res.customClaims,
    providers: res.providers
  })

  // Set data to send with Sentry errors
  setUserSentry({
    email: res.userAuthRecord.email,
    Roles: res.customClaims || []
  })

  return {
    success: true
  }
}

export default router
