import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import PrimeVue from 'primevue/config'
import pinia from './stores'
import router from './router'
import { fontawesomeSetup } from './fontawesomeSetup'
import { primevueSetup } from './primevueSetup'
import AppWrapper from './AppWrapper.vue'
import Divider from './components/DividerHorizontal'
import LoaderSimple from './components/LoaderSimple.vue'
import BcLogo from './components/LogoBusinessCentral.vue'

// Styles
// import 'primevue/resources/themes/lara-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import './assets/styles/aura-light-noir/theme.css'
import './assets/styles/color_brown.css'

// import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.css'
import './assets/styles/main.css'
import './assets/styles/animations.css'
import './assets/styles/icon_overides.css'
import './assets/styles/pure-css-tables.css'
import './assets/styles/business_central.css'

const app = createApp(AppWrapper)

Sentry.init({
  app,
  dsn: 'https://bffff22fc2af46fb991de3e3d5b8963f@o214277.ingest.sentry.io/1447635',
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: import.meta.env.VITE_APPLICATION_ENV === 'development' ? 1.0 : 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay({
      maskAllText: false
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_APPLICATION_ENV
})

// Tag localhost errors
if (window.location.href.includes('localhost'))
  Sentry.setTag('localhost', true)

app.component('Divider', Divider)
app.component('LoaderSimple', LoaderSimple)
app.component('BcLogo', BcLogo)
app.use(PrimeVue, { ripple: true })
fontawesomeSetup()
app.component('FaIcon', FontAwesomeIcon)
app.use(pinia)
app.use(router)
// Register all global PrimeVue Components, Directives
await primevueSetup(app)
app.mount('#app')
