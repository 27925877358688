export default [
  {
    path: '/configurators',
    name: 'configuratorsList',
    component: () => import('../views/ConfiguratorsList.vue')
  },
  {
    path: '/configurators/:id',
    name: 'configurator',
    component: () => import('../views/ConfiguratorWrapper.vue')
  }
]
