export default [
  {
    path: '/configurator-editor',
    name: 'configuratorsList',
    component: () => import('../views/ConfiguratorsList.vue')
  },
  {
    path: '/configurator-editor/:id',
    name: 'configuratorEditorWrapper',
    component: () => import('../views/ConfiguratorEditorWrapper.vue'),
    redirect: (to: any) => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      // Possible break
      // - https://github.com/vuejs/router/blob/main/packages/router/CHANGELOG.md#important-note
      // - https://router.vuejs.org/api/interfaces/RouteLocationOptions.html#state
      return { name: 'ConfiguratorEditorBasicInfo', params: { id: to.params.id } }
    },
    children: [
      {
        path: 'additional-options',
        name: 'ConfiguratorEditorAdditionalOptions',
        component: () => import('../components/ConfiguratorEditorAdditionalOptions.vue')
      },
      {
        path: 'basic-info',
        name: 'ConfiguratorEditorBasicInfo',
        component: () => import('../components/ConfiguratorEditorBasicInfo.vue')
      },
      {
        path: 'fixed-list-prices',
        name: 'ConfiguratorEditorFixedListPrices',
        component: () => import('../components/ConfiguratorEditorFixedListPrices.vue')
      },
      {
        path: 'history',
        name: 'ConfiguratorEditorHistory',
        component: () => import('../components/DocSingleHistory.vue'),
        props: { storeToUse: 'configuratorEditor' }
      },
      {
        path: 'kits',
        name: 'ConfiguratorEditorKits',
        component: () => import('../components/ConfiguratorEditorKits.vue')
      },
      {
        path: 'maintenance',
        name: 'ConfiguratorEditorMaintenance',
        component: () => import('../components/ConfiguratorEditorMaintenance.vue')
      },
      {
        path: 'rules',
        name: 'ConfiguratorEditorRules',
        component: () => import('../components/ConfiguratorEditorRulesList.vue')
      },
      {
        path: 'notes-internal',
        name: 'ConfiguratorEditorNotesInternal',
        component: () => import('../components/DocSingleNotes.vue'),
        props: { storeToUse: 'configuratorEditor', isPrivate: true }
      },
      {
        path: 'part-no-cross-refs',
        name: 'ConfiguratorEditorPartNoCrossRefs',
        component: () => import('../components/ConfiguratorEditorPartNoCrossRefs.vue')
      },
      {
        path: 'pricing',
        name: 'ConfiguratorEditorPricing',
        component: () => import('../components/ConfiguratorEditorPricing.vue')
      },
      {
        path: 'ranges',
        name: 'ConfiguratorEditorRanges',
        component: () => import('../components/ConfiguratorEditorRanges.vue')
      },
      {
        path: 'required-options',
        name: 'ConfiguratorEditorRequiredOptions',
        component: () => import('../components/ConfiguratorEditorRequiredOptions.vue')
      },
    ]
  }
]
