/**
 * These are some generic Firebase actions that can be used across multiple services and components
 * The functions here should be limited to simple abstracted calls to Firebase
 * DO NOT add functions here that need to map data through an interface or `to*` function, handle complex batch/transactions or specific data calls.
 * Data mapping can be handled in the call of this function when it receives the response
 */

import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  // connectFirestoreEmulator,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch
} from 'firebase/firestore'
import { useSentry } from '../composables/sentry'
import { HistoryEventDescriptions, HistoryEventTypes } from '../constants/historyEvents'
import type { ResponseSimple } from '../interfaces/Response'
import { dateToYYYYMMDD } from '../utils/dateFormatting'
import { LogTypes, logProps } from '../utils/LoggingProps'
import { app } from './FirebaseService'
import type { FirestoreDocResponse, FirestoreDocsByMultipleQueriesPayload, FirestoreDocsByQueryPayload, FirestoreOrderBy, FirestoreQuery } from './FirestoreServiceInterfaces'
import { toHistoryEvent } from './HistoryService'

const s = useSentry()
export const fs = getFirestore(app)
// connectFirestoreEmulator(fs, 'localhost', 8082)

export const docResponse: FirestoreDocResponse = {
  data: null,
  exists: false,
  success: false
}

/**
 *
 * Basic validation of Doc Path
 * @param {string} path - Path string to validate
 * @return {object} validPath {boolean} and path {string}
 */
export const validateDocPath = (path: string) => {
  // Remove extra '/'
  if (path.startsWith('/'))
    path = path.substring(1)
  if (path.endsWith('/'))
    path = path.substring(0, path.length - 1)

  // Check path length as a valid path for a single doc
  const validPathArr = path.split('/')
  const validPath = !(validPathArr.length % 2)

  return {
    validPath,
    path
  }
}

/**
 *
 * Basic validation of Doc Path
 * @param {string} path - Path string to validate
 * @return {object} validPath {boolean} and path {string}
 */
export const validateCollectionPath = (path: string) => {
  // Remove extra '/'
  if (path.startsWith('/'))
    path = path.substring(1)
  if (path.endsWith('/'))
    path = path.substring(0, path.length - 1)

  // Check path length as a valid path for a single doc
  const validPathArr = path.split('/')
  const validPath = (validPathArr.length % 2) === 1

  return {
    validPath,
    path
  }
}

const FirestoreService = {
  /**
   * @param {string} docPath - specific path to doc. Can be a document in subcollection, but entire path must be provided
   */
  getDocById: async (docPath: string) => {
    const response = { ...docResponse }
    if (!docPath)
      throw new Error('Document path is required')
    const path = validateDocPath(docPath)
    if (!path.validPath)
      throw new Error('Document path is not valid')

    try {
      const ref = await doc(fs, path.path)
      const docSnap = await getDoc(ref)
      response.exists = docSnap.exists()

      if (!docSnap.exists()) {
        response.success = true
        return response
      }

      response.success = true
      response.data = {
        ...docSnap.data(),
        id: docSnap.id
      }
      return response
    }
    catch (error: any) {
      s.handleError(error)
      return response
    }
  },
  /**
   * getDocByUri is Mostly used for getting Base models and Configurator models
   * @param {any} payload
   * @param {string} payload.collection Current implementation handles only top level collections
   * @param {string} payload.uri The doc uri to look for
   * @return {FirestoreDocResponse}
   */
  getDocByUri: async (payload: any): Promise<FirestoreDocResponse> => {
    const {
      collection: collectionPath,
      uri,
      modelUriProp = 'model_uri'
    } = payload
    const response = { ...docResponse }
    if (!uri || !collection)
      throw new Error('Doc URI and Collection required')
    try {
      const path = validateCollectionPath(collectionPath)
      if (!path.validPath)
        throw new Error('Collection path is not valid')
      const ref = await collection(fs, path.path)
      const q = query(ref, where(modelUriProp, '==', uri))
      const querySnap = await getDocs(q)
      response.success = true
      if (querySnap.empty) {
        response.exists = false
        return response
      }
      response.exists = true
      response.data = {
        ...querySnap.docs[0].data(),
        id: querySnap.docs[0].id
      }
      return response
    }
    catch (error: any) {
      s.handleError(error)
      return response
    }
  },
  /**
   *
   * @param {any} payload
   * @param {string} payload.collectionPath
   * @param {number} payload.limitAmount default is 100
   * @return {any}
   */
  getDocs: async (payload: any) => {
    const {
      collectionPath,
      limitAmount = 100
    } = payload
    const response = { ...docResponse }
    if (!collectionPath)
      throw new Error('Collection path is required')
    const path = validateCollectionPath(collectionPath)
    if (!path.validPath)
      throw new Error('Collection path is not valid')

    response.data = []
    try {
      const ref = await collection(fs, path.path)
      const q = query(ref, limit(limitAmount))
      const querySnap = await getDocs(q)
      response.exists = !querySnap.empty
      response.success = true

      if (querySnap.empty)
        return response

      response.success = true
      querySnap.forEach((i) => {
        response.data.push({
          id: i.id,
          ...i.data()
        })
      })
      return response
    }
    catch (error: any) {
      s.handleError(error)
      response.success = false
      return response
    }
  },
  /**
   * getDocsFromList
   * Implemented with Promise.allSettled to get response from all
   * @param payload
   * @return {any}
   */
  getDocsFromList: async (payload: any): Promise<any> => {
    const {
      collection = null,
      items,
      searchIdProp = 'id'
    } = payload
    try {
      if (!collection || !items?.length)
        throw new Error('Collection and items are required')
      const itemRefs = items.map(async (i: any) => {
        return {
          id: i.id,
          doc: await FirestoreService.getDocById(`${collection}/${i[searchIdProp]}`)
        }
      })
      const res = await Promise.allSettled(itemRefs)
      return res
    }
    catch (error: any) {
      s.handleError(error)
    }
  },
  // TODO Migrate all to Multiple Query
  getDocsByQuery: async (payload: FirestoreDocsByQueryPayload): Promise<typeof docResponse> => {
    const {
      collection: collectionPath,
      prop,
      operator,
      value,
      // limit = null, // TODO
      returnFirst = false,
      returnLast = false
    } = payload
    const response = {
      ...docResponse
    }
    const docs = [] as any[]
    try {
      const path = validateCollectionPath(collectionPath)
      if (!path.validPath)
        throw new Error('Collection path is not valid')
      const ref = await collection(fs, path.path)
      const q = query(ref, where(prop, operator, value))
      const querySnap = await getDocs(q)
      response.success = true
      response.exists = !querySnap.empty

      if (querySnap.empty)
        return response

      querySnap.forEach((i) => {
        docs.push({
          id: i.id,
          ...i.data()
        })
      })
      if (returnFirst) {
        response.data = { ...docs[0] }
        return response
      }
      if (returnLast) {
        response.data = { ...docs[docs.length - 1] }
        return response
      }
      response.data = docs
      return response
    }
    catch (error: any) {
      s.handleError(error)
      return docResponse
    }
  },
  /**
   *
   * @param {any} payload
   * @param {string} payload.collection
   * @param {FirestoreQuery[]} payload.conditions prop, operator, value
   * @param {boolean} payload.returnFirst optional
   * @param {boolean} payload.returnLast optional
   * @return {typeof docResponse}
   */
  getDocsByMultipleQueries: async (payload: FirestoreDocsByMultipleQueriesPayload): Promise<typeof docResponse> => {
    const {
      collection: collectionPath,
      conditions = [] as FirestoreQuery[],
      ordering = [] as FirestoreOrderBy[],
      returnFirst = false,
      returnLast = false
    } = payload
    const response = {
      ...docResponse
    }
    const docs = [] as any[]
    try {
      const path = validateCollectionPath(collectionPath)
      if (!path.validPath)
        throw new Error('Collection path is not valid')
      const ref = await collection(fs, path.path)
      const queries: any = []
      conditions.forEach((i: FirestoreQuery) => {
        queries.push(where(i.prop, i.operator, i.value))
      })
      ordering.forEach((i: FirestoreOrderBy) => {
        queries.push(orderBy(i.prop, i.value))
      })
      const q = query(ref, ...queries)
      const querySnap = await getDocs(q)
      response.success = true
      response.exists = !querySnap.empty

      if (querySnap.empty)
        return response

      querySnap.forEach((i) => {
        docs.push({
          ...i.data(),
          id: i.id
        })
      })
      if (returnFirst) {
        response.data = { ...docs[0] }
        return response
      }
      if (returnLast) {
        response.data = { ...docs[docs.length - 1] }
        return response
      }

      response.data = docs
      return response
    }
    catch (error: any) {
      if (error.toString().includes('The query requires an index'))
        console.error(error)
      s.handleError(error)
      return docResponse
    }
  },
  updateArray: async (payload: any) => {
    const {
      docPath,
      prop,
      data,
      updateType = 'arrayUnion'
    } = payload
    if (!docPath)
      throw new Error('Document path is required')
    const path = validateDocPath(docPath)
    if (!path.validPath)
      throw new Error('Document path is not valid')
    try {
      const ref = doc(fs, path.path)
      if (updateType === 'arrayUnion') {
        await setDoc(ref, {
          [prop]: arrayUnion({ ...data }),
          ...logProps(LogTypes.LastModified)
        }, { merge: true })
      }
      else if (updateType === 'arrayRemove') {
        await updateDoc(ref, {
          [prop]: arrayRemove(data),
          ...logProps(LogTypes.LastModified)
        }, { merge: true })
      }
      else {
        throw new Error('Invalid array update type')
      }
      return {
        success: true
      }
    }
    catch (error: any) {
      s.handleError(error)
      return {
        success: false
      }
    }
  },
  /**
   * Basic Update for existing Firestore Doc
   * @param {string} payload
   * @param {string} payload.docPath Path to doc
   * @param {any} payload.data Update object. Logging props added in updateDoc()
   */
  updateDoc: async (payload: any): Promise<ResponseSimple> => {
    const {
      docPath,
      data
    } = payload
    if (!docPath)
      throw new Error('Document path is required')
    const path = validateDocPath(docPath)
    if (!path.validPath)
      throw new Error('Document path is not valid')

    try {
      // console.log({ data })
      const ref = await doc(fs, path.path)
      await updateDoc(ref, {
        ...data,
        ...logProps(LogTypes.LastModified)
      })
      return {
        success: true
      }
    }
    catch (error: any) {
      s.handleError(error)
      return {
        success: false
      }
    }
  },
  /**
   *
   * @param {any} payload
   * @param {boolean} payload.createAutoId Default false
   * @param {string} payload.docPath collection only if createdAutoId else provide collection & doc
   * @param {any} payload.data Data payload for doc
   * @return {ResponseSimple}
   */
  setFirestoreDoc: async (payload: any) => {
    const {
      createAutoId = false,
      docPath,
      data
    } = payload
    if (!docPath)
      throw new Error('Document path is required')
    try {
      let id
      let path
      // If id is specified then use setDoc:merge
      if (!createAutoId) {
        path = validateDocPath(docPath)
        if (!path.validPath)
          throw new Error('Document path is not valid')
        const ref = await doc(fs, path.path)
        await setDoc(ref, data, { merge: true })
        id = ref.id
      }
      // If this is a new doc that requires an id then auto create the id using addDoc()
      else {
        path = validateCollectionPath(docPath)
        if (!path.validPath)
          throw new Error('Collection path is not valid')
        const res = await addDoc(collection(fs, path.path), data)
        id = res.id
      }
      // Return the id so it can be used if needed
      return {
        success: true,
        data: {
          id
        }
      }
    }
    catch (error: any) {
      s.handleError(error)
      console.error(error)
      return {
        success: false
      }
    }
  },
  /**
   * For setting docs that also have a history doc in a subcollection.
   * TODO: Move to Firebase Function for increased security
   * @param {any} payload
   * @param {string} payload.docPath Path to main doc
   * @param {any} payload.data Data to set
   * @param {any} payload.history History object
   * @return {ResponseSimple}
   */
  upsertFirestoreDocWithHistory: async (payload: any) => {
    const {
      docPath,
      data,
      eventType = HistoryEventTypes.modified,
      history,
      upsertType = 'set'
    } = payload
    if (!docPath)
      throw new Error('Document path is required')
    let path: any
    upsertType === 'add'
      ? path = validateCollectionPath(docPath)
      : path = validateDocPath(docPath)

    if (!path.validPath)
      throw new Error('Document path is not valid')
    try {
      const batch = writeBatch(fs)

      let docRef: any

      if (upsertType === 'add')
        docRef = await doc(collection(fs, path.path))
      else
        docRef = await doc(fs, path.path)

      if (upsertType === 'update')
        batch.update(docRef, data)

      else
        batch.set(docRef, data, { merge: true })

      const historyDate = dateToYYYYMMDD()
      const historyRef = await doc(fs, `${docRef.path}/history/${historyDate}`)

      batch.set(historyRef, {
        key: historyDate,
        events: arrayUnion(toHistoryEvent({
          type: eventType, // Shifted to key rather than text in v3
          desc: HistoryEventDescriptions.changesMadeToDoc,
          details: history ? history.join(', ') : null
        }))
      }, { merge: true })

      await batch.commit()
      return {
        success: true,
        data: { id: docRef.id, path: docRef.path }
      }
    }
    catch (error: any) {
      s.handleError(error)
      return {
        success: false
      }
    }
  },
  // This is for migration use and not updating a prop
  // No logging props are used in this call
  tmp_setDocProp: async (payload: any) => {
    const {
      docPath,
      prop,
      data
    } = payload
    if (!docPath)
      throw new Error('Document path is required')
    const path = validateDocPath(docPath)
    if (!path.validPath)
      throw new Error('Document path is not valid')
    try {
      const ref = await doc(fs, path.path)
      setDoc(ref, { [prop]: data }, { merge: true })
    }
    catch (error: any) {
      s.handleError(error)
    }
  },
  deleteDoc: async (payload: any) => {
    const {
      docPath
    } = payload
    if (!docPath)
      throw new Error('Document path is required')
    const path = validateDocPath(docPath)
    if (!path.validPath)
      throw new Error('Document path is not valid')

    try {
      const ref = await doc(fs, path.path)
      deleteDoc(ref)
      return {
        success: true
      }
    }
    catch (error: any) {
      s.handleError(error)
      return {
        success: false
      }
    }
  }
}

export default FirestoreService
