export default [
  {
    path: '/base-model-editor',
    name: 'BaseModelsList',
    component: () => import('../views/BaseModelsList.vue')
  },
  {
    path: '/base-models-editor/:id',
    name: 'baseModelEditor',
    component: () => import('../views/BaseModelEditorWrapper.vue'),
    redirect: (to: any) => {
      return {
        name: 'BaseModelEditorBasicInfo',
        params: {
          id: to.params.id
        }
      }
    },
    children: [
      {
        path: 'additional-resources',
        name: 'BaseModelEditorAdditionalResources',
        component: () => import('../components/BaseModelEditorAdditionalResources.vue')
      },
      {
        path: 'applications',
        name: 'BaseModelEditorApplications',
        component: () => import('../components/BaseModelEditorApplications.vue')
      },
      {
        path: 'basic-info',
        name: 'BaseModelEditorBasicInfo',
        component: () => import('../components/BaseModelEditorBasicInfo.vue')
      },
      {
        path: 'features',
        name: 'BaseModelEditorFeatures',
        component: () => import('../components/BaseModelEditorFeatures.vue')
      },
      {
        path: 'notes-internal',
        name: 'BaseModelEditorNotesInternal',
        component: () => import('../components/DocSingleNotes.vue'),
        props: { storeToUse: 'baseModelEditor', isPrivate: true }
      },
      {
        path: 'specs',
        name: 'BaseModelEditorSpecs',
        component: () => import('../components/BaseModelEditorSpecs.vue')
      }
    ]
  }
]
