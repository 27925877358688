/**
 * `sumPriceOption` is used to handle a rolling sum of all sell prices in the configuration by evaluating the option passed to it.
 * If an issue is detected is sets the price to `9999.99`
 * Calculations for list adders and net adders are handled in `getOptionPrice`
 * @param {any} payload.option Option object
 * @param {number} payload.total Rolling total
 * @param {number} payload.multiplier Default is 1 for no discount
 */
import { priceDefault } from '../constants/priceDefault'
import { getOptionPrice } from './PricingServiceGetOptionPrice'
import { parseDecimal } from './PricingServiceParseDecimal'

export const sumPriceOption = (payload: any) => {
  const {
    option,
    total,
    multiplier = 1
  } = payload
  let price = total
  const optionPrice = getOptionPrice({ option, multiplier })
  optionPrice !== priceDefault
    ? price += optionPrice
    : price = priceDefault
  const updatedPrice = parseDecimal(price)
  return updatedPrice
}
