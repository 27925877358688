/**
 * Daughtridge Sales uses 9999.99 as a price that has been picked to indicate that an item does not have a true list price in the system.
 * This indicator can exist in any pricing field but tends to be in List Price and Standard Cost.
 * It can indicate an error in calculation, known pricing fluctuations that require contacting the vendor, etc
 * https://www.notion.so/dascosales/What-is-with-9999-99-price-1f1c5b3507044d6ebbf8778704b2a933
 */
import { useSentry } from '../composables/sentry'
import { triggers } from '../constants/priceDefault'
import { getOptionPrice } from './PricingServiceGetOptionPrice'

const s = useSentry()

export const checkContactForPricing = (payload: any) => {
  const { configuration } = payload

  try {
    // Get keys of required option objects that have list pricing
    const keys = [...Object.keys(configuration.requiredOptions)]

    const arr = []
    for (const i of keys)
      arr.push(triggers.includes(getOptionPrice({ option: configuration.requiredOptions[i] })))

    // Check if additional options array and evaluate
    if (configuration.additionalOptions?.length) {
      configuration.additionalOptions.forEach((o: any) => {
        const x = triggers.includes(getOptionPrice({ option: o }))
        arr.push(x)
      })
    }

    // Check if range and evaluate
    if (configuration?.range?.code)
      configuration?.range?.list_price === null ? arr.push(false) : arr.push(triggers.includes(getOptionPrice({ option: configuration.range })))

    return arr.includes(true)
  }
  catch (error: any) {
    s.handleError(error)
    return true
  }
}
