import { useSentry } from '../composables/sentry'
import { priceDefault } from '../constants/priceDefault'
import type { ResponseSimple } from '../interfaces/Response'
import { checkContactForPricing } from './PricingServiceCheckContactForPricing'
import { sumPriceTotal } from './PricingServiceSumPriceTotal'
import { parseDecimal } from './PricingServiceParseDecimal'

const s = useSentry()

interface Payload {
  item: any
  multiplier: any
  qty: number
}

/**
 * Calculate Item Cost
 * Currently only for items in configurator
 * Not handling net adders correctly
 * @param {Payload} payload
 * @return {ResponseSimple}
 */

export const calculateItemCost = (payload: Payload): ResponseSimple => {
  const {
    item,
    multiplier,
    qty = 1 // single configurator OR TODO pcpgTotal from quote
  } = payload

  try {
    if (!item)
      throw new Error('Error: Item required')

    const contact = checkContactForPricing({ configuration: item?.configuration })

    if (contact || item?.list_price === priceDefault) {
      return {
        success: true,
        data: {
          single: priceDefault,
          subtotal: priceDefault,
        }
      }
    }

    const cost = item?.price_override ? +item?.price_override_value : sumPriceTotal({ item, multiplier })

    return {
      success: true,
      data: {
        single: cost !== priceDefault ? parseDecimal(cost) : priceDefault,
        subtotal: (qty && cost !== priceDefault) ? parseDecimal(parseDecimal(cost) * item.qty) : priceDefault
      }
    }
  }
  catch (error: any) {
    s.handleError(error)
    return {
      success: false,
      data: {
        single: priceDefault,
        subtotal: priceDefault
      }
    }
  }
}
