export default [
  {
    path: 'dev-team',
    name: 'dev-team',
    component: () => import('../views/DevTeam.vue'),
    children: [
      {
        path: 'update-configurations-list-price',
        name: 'dev-team-update-configurations-list-price',
        component: () => import('../views/DevTeamUpdateConfigurationsListPrice.vue')
      },
      {
        path: 'patch-fixed-list-base',
        name: 'dev-team-patch-fixed-list-base',
        component: () => import('../views/DevTeamPatchFixedListBase.vue')
      },
      {
        path: 'patch-configurator-categories',
        name: 'dev-team-patch-configurator-categories',
        component: () => import('../views/DevTeamPatchConfiguratorCategories.vue')
      },
      {
        path: 'generate-suggested-order',
        name: 'generate-suggested-order',
        component: () => import('../views/GenerateSuggestedOrder.vue')
      }
    ]
  }
]
