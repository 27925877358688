import {
  OAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
  signInWithPopup,
  signOut
} from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import { useSentry } from '../composables/sentry'
import { clearSessionStorage } from '../utils/clearSessionStorage'
import { toUser } from './UserInterfaces'
import { app } from './FirebaseService'
import FirestoreService from './FirestoreService'
import { functions } from './FirebaseFunctionsService'

const s = useSentry()
const auth = getAuth(app)

const roles = {
  powerUser: 'power-user',
  employee: 'employee',
  outsideSalesRep: 'outside-sales-rep'
}

const AuthService = {
  initAuth: async () => {
    try {
      const authStatePromise = new Promise((resolve) => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            resolve({
              signedIn: true,
              user
            })
          }
          else {
            resolve({
              signedIn: false,
              user: { uid: null }
            })
          }
        })
      })
      const authState: any = await authStatePromise
      if (!authState.signedIn)
        return authState
      const user = await AuthService.getUserData({
        authState
      })
      return {
        success: true,
        ...user
      }
    }
    catch (error: any) {
      s.handleError(error)
    }
  },
  // Deprecated 2024-05-02
  // To be removed in next version
  signInWithAuthProvider: async (provider: string) => {
    try {
      let authProvider: any
      if (provider === 'Google') {
        // authProvider = new fb.auth.GoogleAuthProvider()
      }
      else if (provider === 'microsoft') {
        /**
         * https://techcommunity.microsoft.com/t5/azure-active-directory-identity/update-your-applications-to-use-microsoft-authentication-library/ba-p/1257363
         * Unsure how this impacts us. Cannot find anything in firebase/auth code in github or issues invovling this
         * Possible impact date June 30th, 2022 as EOL for ADAL and Azure AD Graph API
         */
        authProvider = new OAuthProvider('microsoft.com')
        /**
         * Needed in case user needs to switch accounts or accidentally signs into the wrong account
         * `prompt`: https://docs.microsoft.com/en-us/azure/active-directory/azuread-dev/v1-protocols-oauth-code
         */
        authProvider.setCustomParameters({
          prompt: 'select_account'
        })
      }
      const res = await signInWithPopup(auth, authProvider)
      const user = await AuthService.getUserData({
        authState: res
      })
      return {
        success: true,
        ...user
      }
    }
    catch (error: any) {
      s.handleError(error)
    }
  },
  requestSignInLink: async (payload: { email: string }) => {
    const {
      email
    } = payload
    window.localStorage.setItem('dasco-email-for-sign-in', email)
    const sendSignInWithEmailLinkRequest = httpsCallable(functions, 'auth-sendSignInLinkDtp')
    const res: any = await sendSignInWithEmailLinkRequest({ email })
    return {
      success: res.data.success
    }
  },
  signInWithLink: async (href: string) => {
    const isLink = isSignInWithEmailLink(auth, href)

    if (!isLink) {
      return {
        success: true,
        data: null
      }
    }
    try {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('dasco-email-for-sign-in') || ''
      // let email = ''
      let promptCount = 0
      if (!email?.length && promptCount < 2) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        // eslint-disable-next-line no-alert
        email = window.prompt('Please provide your email for confirmation') || ''
        promptCount++
      }
      // The client SDK will parse the code from the link for you.
      const res = await signInWithEmailLink(auth, email, href)
      const user: any = await AuthService.getUserData({
        authState: res.user
      })

      window.localStorage.removeItem('dasco-email-for-sign-in')
      return {
        success: true,
        ...user // TODO refactor to data: { ...user }
      }
    }
    catch (error: any) {
      // Clear email from storage.
      window.localStorage.removeItem('dasco-email-for-sign-in')
      // Some error occurred, you can inspect the code: error.code
      // Common errors could be invalid email and invalid or expired OTPs.
      s.handleError(error)
      return {
        success: false
      }
    }
  },
  // Map custom claims from the user's token
  mapUserClaimsAndRoles: async (): Promise<string[]> => {
    if (!auth.currentUser)
      return []
    // Get the user's token
    const token = await auth.currentUser.getIdTokenResult()
    // Map base role and additional roles
    const roles = [token.claims.baseRole]
      .concat(token.claims.additionalRoles)
      .concat(token.claims.roles)
    // Map additional claims as kebab roles
    if (token.claims.isAdmin)
      roles.push('admin')
    if (token.claims.isEmployee)
      roles.push('employee')
    if (token.claims.isPowerUser)
      roles.push('power-user')
    // return array of unique values
    return [...new Set(roles)] as string[]
  },
  // This is the shared logic among methods for signing in and registering a currently signed in user
  getUserData: async (payload: any) => {
    const {
      authState
    } = payload
    try {
      // Handle custom claims first to see if user is allowed to login to portal
      const customClaims: string[] = await AuthService.mapUserClaimsAndRoles()
      const canAccessPortal = await AuthService.canAccessPortal(customClaims)

      if (canAccessPortal) {
        const userDatabaseRecord = await FirestoreService.getDocById(`users/${authState.user.uid}`)

        // TODO
        // const preferences = await FirestoreService.getDocById(`users/${authState.user.uid}/resources/preferences`)
        // delete preferences.data?.id

        const permissions = await FirestoreService.getDocs({
          collectionPath: 'permissions',
          limit: null
        })
        const providers = await AuthService.currentUserProviders(authState.user.email)

        return {
          userAuthRecord: authState.user,
          userDatabaseRecord: { ...toUser(userDatabaseRecord.data) },
          permissions: permissions.success ? [...permissions.data] : [],
          customClaims,
          providers,
          // preferences: preferences.data
        }
      }
      else {
        // TODO: Send report of login attempt
        AuthService.signOutFirebase()
      }
    }
    catch (error: any) {
      s.handleError(error)
      return {
        // what to return here?
        success: false
      }
    }
  },
  signOutFirebase: () => {
    try {
      signOut(auth)
      clearSessionStorage()
    }
    catch (error: any) {
      s.handleError(error)
    }
  },
  canAccessPortal: async (claims: string[]): Promise<boolean> => {
    const allowedRoles = [roles.powerUser, roles.employee, roles.outsideSalesRep]
    return claims.some((r: string) => allowedRoles.includes(r))
  },
  // Deprecated 2024-05-02
  // linkWithMicrosoft: async () => {
  //   // https://firebase.google.com/docs/auth/web/account-linking#link-federated-auth-provider-credentials-to-a-user-account
  //   // Legacy Version
  //   /**
  //    * When linking a Google Account to your Firebase account signing in seems to be enough
  //    * When link other auth providers such as Microsoft use `linkWith*` after the user is signed into one of their other auth providers
  //    */
  //   // try {
  //   //   const provider = new fb.auth.OAuthProvider('microsoft.com')
  //   //   const res = await auth.currentUser.linkWithPopup(provider)
  //   //   return {
  //   //     success: true,
  //   //     message: 'Microsoft account linked'
  //   //   }
  //   // } catch (error: any) {
  //   //   s.handleError(error)
  //   // }
  // },
  currentUserProviders: async (email: string | null = null) => {
    /**
     * This can be used to validate if a password email should be sent or if a user should be directed to sign in with a federated auth provider
     */
    if (!email)
      return []
    try {
      const providers = await fetchSignInMethodsForEmail(auth, email)
      const enabledProviders = ['google.com', 'microsoft.com']
      return {
        hasPassword: providers.includes('password'),
        hasOtherProviders: providers.some(el => enabledProviders.includes(el)),
        providers
      }
    }
    catch (error: any) {
      s.handleError(error)
    }
  }
}

export default AuthService
