import type { ObjectKeys } from '../interfaces/ObjectKeys'

export const lineItemScope: ObjectKeys = {
  include: {
    code: 'include-in-subtotal',
    desc: 'Item included in the subtotal'
  },
  exclude: {
    code: 'exclude-from-subtotal',
    desc: 'Item not included in the subtotal'
  },
  substitutionInclude: {
    code: 'substitution-include-in-subtotal',
    desc: 'Substitution included in the subtotal'
  },
  substitutionExclude: {
    code: 'substitution-exclude-from-subtotal',
    desc: 'Substitution not included in the subtotal'
  },
  suggestedInclude: {
    code: 'suggested-include-in-subtotal',
    desc: 'Suggestion included in the subtotal'
  },
  suggestedExclude: {
    code: 'suggested-exclude-from-subtotal',
    desc: 'Suggestion not included in the subtotal'
  }
}

export const lineItemScopeDesc = (code: string) => {
  const key = Object.keys(lineItemScope).find(key => lineItemScope[key].code === code)
  if (!key)
    return lineItemScope.include
  return lineItemScope[key].desc
}

export const lineItemScopeArray = Object.values(lineItemScope)

export const lineItemScopeCodesArray = lineItemScopeArray.map(i => i.code)
