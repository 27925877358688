import { useSentry } from '../composables/sentry'
import { priceDefault } from '../constants/priceDefault'
import { sumPriceOption } from './PricingServiceSumPriceOption'

const s = useSentry()

export const sumPriceTotal = (payload: any): number => {
  const {
    item,
    multiplier = 1
  } = payload

  let total = 0

  try {
    total = item.list_price_base * multiplier

    // // If fixed list price item is on quote it will already have a list price in the object
    if (item?.list_prices_has_fixed && item?.list_price_total) {
      if (!item.list_price_fixed_base)
        return priceDefault
      total = item.list_price_fixed_base * multiplier
    }
    // Else find it in the list_prices_fixed object
    else if (item?.list_prices_has_fixed && item?.list_prices_fixed) {
      const desc = item?.base_item?.trim()
      total = +item?.list_prices_fixed[desc] * multiplier
    }
    else {
      // Required Options
      for (const option of Object.values(item?.configuration?.requiredOptions)) {
        total = sumPriceOption({ option, total, multiplier })
        if (total === priceDefault)
          return priceDefault
      }

      // Range
      if (item?.configuration?.range) {
        total = sumPriceOption({ option: item?.configuration.range, total, multiplier })
        if (total === priceDefault)
          return priceDefault
      }
    }
    // Options
    item?.configuration?.additionalOptions?.forEach((option: any) => {
      total = sumPriceOption({ option, total, multiplier })
      if (total === priceDefault)
        return priceDefault
    })
    return total
  }
  catch (error: any) {
    s.handleError({ ...error, msg: 'Error on a configurator in sumPriceTotal' })
    return priceDefault
  }
}
