import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faChartMixed,
  faCheckSquare,
  faFilePen,
  faPaperPlane,
  faPencil,
  faSquare,
  faSquarePollVertical,
  faStar,
} from '@fortawesome/pro-regular-svg-icons'

import {
  faBan as faBanSolid,
  faCaretDown as faCaretDownSolid,
  faCaretRight as faCaretRightSolid,
  faCaretUp as faCaretUpSolid,
  faChartBar as faChartBarSolid,
  faCheckCircle as faCheckCircleSolid,
  faCheck as faCheckSolid,
  faChevronDoubleDown as faChevronDoubleDownSolid,
  faChevronDoubleUp as faChevronDoubleUpSolid,
  faChevronDown as faChevronDownSolid,
  faChevronRight as faChevronRightSolid,
  faChevronUp as faChevronUpSolid,
  faCircleQuestion as faCircleQuestionSolid,
  faCircle as faCircleSolid,
  faCopy as faCopySolid,
  faDatabase as faDatabaseSolid,
  faDownload as faDownloadSolid,
  faEllipsisVertical as faEllipsisVerticalSolid,
  faExclamationTriangle as faExclamationTriangleSolid,
  faExternalLink as faExternalLinkSolid,
  faFilePdf as faFilePdfSolid,
  faInfoCircle as faInfoCircleSolid,
  faLoader as faLoaderSolid,
  faMinus as faMinusSolid,
  faPaperPlane as faPaperPlaneSolid,
  faPlus as faPlusSolid,
  faQuestion as faQuestionSolid,
  faRefresh as faRefreshSolid,
  faSearch as faSearchSolid,
  faShelves as faShelvesSolid,
  faStar as faStarSolid,
  faTimesCircle as faTimesCircleSolid,
  faTimes as faTimesSolid,
  faTrash as faTrashSolid,
  faUpload as faUploadSolid
} from '@fortawesome/pro-solid-svg-icons'

import {
  faBuilding as faBuildingLight,
  faCircleNotch as faCircleNotchLight,
  faFileInvoice as faFileInvoiceLight,
  faFileLines as faFileLinesLight,
  faGaugeHigh as faGaugeHighLight,
  faPaperPlane as faPaperPlaneLight,
  faShelves as faShelvesLight,
  faUsers as faUsersLight
} from '@fortawesome/pro-light-svg-icons'

import {
  faArrowsRepeat as faArrowsRepeatDuo,
  faBuildingCircleExclamation as faBuildingCircleExclamationDuo,
  faBuilding as faBuildingDuo,
  faCogs as faCogsDuo,
  faDollar as faDollarDuo,
  faExclamationTriangle as faExclamationTriangleDuo,
  faFileInvoice as faFileInvoiceDuo,
  faFileLines as faFileLinesDuo,
  faGaugeHigh as faGaugeHighDuo,
  faInfoCircle as faInfoCircleDuo,
  faLoader as faLoaderDuo,
  faLock as faLockDuo,
  faMap as faMapDuo,
  faRightLeft as faRightLeftDuo,
  faSort as faSortDuo,
  faUser as faUserDuo
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  faArrowsRepeatDuo,
  faBanSolid,
  faBuildingCircleExclamationDuo,
  faBuildingDuo,
  faBuildingLight,
  faCaretDownSolid,
  faCaretRightSolid,
  faCaretRightSolid,
  faCaretUpSolid,
  faChartBarSolid,
  faChartMixed,
  faCheckCircleSolid,
  faCheckSolid,
  faCheckSquare,
  faChevronDoubleDownSolid,
  faChevronDoubleUpSolid,
  faChevronDownSolid,
  faChevronRightSolid,
  faChevronUpSolid,
  faCircleNotchLight,
  faCircleQuestionSolid,
  faCircleSolid,
  faCogsDuo,
  faCopySolid,
  faDatabaseSolid,
  faDollarDuo,
  faDownloadSolid,
  faEllipsisVerticalSolid,
  faExclamationTriangleDuo,
  faExclamationTriangleSolid,
  faExternalLinkSolid,
  faFileInvoiceDuo,
  faFileInvoiceLight,
  faFileLinesDuo,
  faFileLinesLight,
  faFilePdfSolid,
  faFilePen,
  faGaugeHighDuo,
  faGaugeHighLight,
  faInfoCircleDuo,
  faInfoCircleSolid,
  faLoaderDuo,
  faLoaderSolid,
  faLockDuo,
  faMapDuo,
  faMinusSolid,
  faPaperPlane,
  faPaperPlaneLight,
  faPaperPlaneSolid,
  faPencil,
  faPlusSolid,
  faRefreshSolid,
  faRightLeftDuo,
  faSearchSolid,
  faShelvesLight,
  faShelvesSolid,
  faSortDuo,
  faSquare,
  faSquarePollVertical,
  faStar,
  faStarSolid,
  faTimesCircleSolid,
  faTimesSolid,
  faTrashSolid,
  faUploadSolid,
  faUserDuo,
  faUsersLight,
  faQuestionSolid
)

export function fontawesomeSetup() {
  return library
}
