import { captureException } from '@sentry/vue'

export const useSentry = () => {
  const handleError = (error: Error) => {
    captureException(error)
  }

  return {
    handleError
  }
}
