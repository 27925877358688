<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue'
import { storeToRefs } from 'pinia'
import PMenu from 'primevue/menu'
import { useActiveUserStore } from '../stores/ActiveUserStore'
import { useCustomerClassStore } from '../stores/CustomerClassStore'
import AppMenuLogo from './AppMenuLogo.vue'

const emit = defineEmits(['goHome'])

const activeUserStore = useActiveUserStore()
const customerClassStore = useCustomerClassStore()
const { customerClass } = storeToRefs(customerClassStore)

const environment = ref(import.meta.env.VITE_APPLICATION_ENV)
const userMenu = ref()
const toggle = (event: any) => {
  userMenu.value.toggle(event)
}

const ActiveQuote = defineAsyncComponent(() => import('./AppTopBarActiveQuote.vue'))
</script>

<template>
  <div
    class="flex align-items-center px-5 surface-section shadow-2 relative lg:static border-bottom-1 surface-border"
    style="height:60px"
  >
    <div class="flex align-items-center mr-4">
      <app-menu-logo
        class="cursor-pointer"
        @click="emit('goHome')"
      />
    </div>
    <slot name="commandMenu" />
    <div />
    <div class="flex-grow-1" />
    <ul
      class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row surface-section border-1 lg:border-none surface-border right-0 top-100 z-0 shadow-2 lg:shadow-none absolute lg:static"
      style="z-index: 0;"
    >
      <li>
        <div
          v-if="environment !== 'production'"
          class="text-3xl px-2 py-1 border-round border-3 mr-4 text-purple-500 border-purple-200"
        >
          <fa-icon
            icon="fas fa-exclamation-triangle"
            class="text-yellow-400"
          />
          TEST DATABASE
        </div>
      </li>
      <li>
        <div
          v-tooltip.bottom="`Active customer class. Determined by active quote or defaults to '99'.`"
          class="border-round-2xl border-1 border-primary-200 p-2 bg-primary-100 text-primary-800 font-bold cursor-help"
        >
          {{ customerClass }}
        </div>
      </li>
      <active-quote />
      <!-- <li>
        <a
          v-ripple
          class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors p-ripple"
        >
          <i class="fa fa-inbox text-base lg:text-2xl mr-2 lg:mr-0" />
          <span class="block lg:hidden font-medium">Inbox</span>
        </a>
      </li> -->
      <!-- <li>
        <a
          v-ripple
          class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors p-ripple"
        >
          <i class="fa-duotone fa-bell text-base lg:text-2xl mr-2 lg:mr-0" />
          <span class="block lg:hidden font-medium">Notifications</span>
        </a>
      </li> -->
      <li
        aria-haspopup="true"
        aria-controls="user_menu"
        class="border-top-1 surface-border lg:border-top-none cursor-pointer hover:surface-100 border-round cursor-pointer transition-duration-150 transition-colors p-ripple p-1"
        @click="toggle"
      >
        <img
          alt="User avatar - auto generated from email"
          :src="activeUserStore.avatar"
          class="mr-3 lg:mr-0"
          style="width: 32px; height: 32px"
        >
        <div class="block lg:hidden">
          <div class="text-900 font-medium">
            {{ activeUserStore.user.firstName }}
          </div>
          <span class="text-600 font-medium text-sm">
            {{ activeUserStore.user.title || null }}
          </span>
        </div>
        <p-menu
          id="user_menu"
          ref="userMenu"
          :model="activeUserStore.userMenuItems"
          popup
        />
      </li>
    </ul>
  </div>
</template>
