import { useSentry } from '../composables/sentry'
import { priceDefault } from '../constants/priceDefault'
import type { ResponseSimple } from '../interfaces/Response'
import { parseDecimal } from './PricingServiceParseDecimal'

const s = useSentry()

interface Payload {
  item: any
}

export const calculateItemListPriceNonConfigured = (payload: Payload): ResponseSimple => {
  const {
    item
  } = payload
  try {
    if (!item || !item?.qty)
      throw new Error('Error: Item and quantity required')

    const listPrice = item?.list_price_total
    // Check if pricing has priceDefault === 9999.99

    // If there is no value in list price (0 is a value) or list price === priceDefault return
    if ((!listPrice && listPrice !== 0) || listPrice === priceDefault) {
      return {
        success: true,
        data: {
          single: priceDefault,
          subtotal: priceDefault,
        }
      }
    }

    return {
      success: true,
      data: {
        single: parseDecimal(listPrice),
        subtotal: (item.qty && listPrice !== priceDefault) ? parseDecimal(parseDecimal(listPrice) * item.qty) : priceDefault
      }
    }
  }
  catch (error: any) {
    s.handleError(error)
    return {
      success: false,
      data: {
        single: priceDefault,
        subtotal: priceDefault
      }
    }
  }
}
