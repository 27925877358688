<template>
  <div class="p-card p-component shadow-none border-1 border-gray-300">
    <div
      v-if="$slots.header"
      class="p-card-header"
    >
      <slot name="header" />
    </div>
    <div
      v-if="$slots.titleWithSpeedDial"
      class="pl-2 py-2 text-sm font-bold text-primary-700"
    >
      <div class="flex align-items-center justify-content-between">
        <slot name="titleWithSpeedDial" />
      </div>
    </div>
    <div class="flex align-items-center justify-content-center">
      <div
        v-if="$slots.menuLeft"
      >
        <slot name="menuLeft" />
      </div>
      <div
        v-if="$slots.content"
        class="p-card-body"
        :class="!$slots.footer ? 'pb-2' : ''"
      >
        <div
          v-if="$slots.title"
          class="p-card-title text-primary-700"
        >
          <slot name="title" />
        </div>
        <div
          v-if="$slots.subtitle"
          class="p-card-subtitle text-gray-400"
        >
          <slot name="subtitle" />
        </div>
        <div :class="`p-card-content ${$slots.titleWithSpeedDial ? 'pt-0' : ''}`">
          <slot name="content" />
        </div>
      </div>
      <div class="p-card-body py-2 px-2">
        <slot />
      </div>
    </div>
    <div
      v-if="$slots.footer"
      class="p-card-footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<style scoped>
.p-card-header img {
  width: 100%;
}

.p-card-body {
  padding-bottom: 0 !important;
}

.p-card-footer {
  padding-top: 0 !important;
}

/* Styling for using Speed Dial in titleWithActions */
:deep().p-speeddial-direction-left {
  position: relative;
  right: 5px;
  top: 5px;
}

:deep().p-speeddial-button.p-button.p-button-icon-only {
  /* width: 3rem;
  height: 3rem; */
}

:deep().p-speeddial-action {
  text-decoration: none;
  background-color: transparent;
  color: #616161;
}

:deep().p-speeddial-action:hover {
  text-decoration: none;
  background-color: #F5F5F5;
  color: #616161;
}
</style>
