<script setup>
import { useAppStore } from '../stores/AppStore'

const year = new Date().getFullYear()
const appStore = useAppStore()
</script>

<template>
  <div class="surface-ground px-4 md:px-6 lg:px-8">
    <div class="py-2 flex flex-column sm:flex-row sm:align-items-center justify-content-between">
      <div>
        <div class="mt-2 line-height-3">
          &copy; {{ year }} Daughtridge Sales All rights reserved | v{{ appStore.version }} | Environment: <span class="p-1 bg-gray-300 border-round text-primary-700">{{ appStore.environment }}</span>
        </div>
      </div>
      <!-- Add other links here -->
      <!-- <div class="mt-3 sm:mt-0">
        <a class="cursor-pointer text-500 transition-colors transition-duration-150 hover:text-700">
          <i class="pi pi-twitter text-xl"></i>
        </a>
        <a class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700">
          <i class="pi pi-facebook text-xl"></i>
        </a>
        <a class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700">
          <i class="pi pi-github text-xl"></i>
        </a>
      </div> -->
    </div>
  </div>
</template>
