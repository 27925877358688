/**
 * System requires a numeric price to handle math
 * This is the default price that is used if none is provided.
 *
 * This is a price that has been picked to indicate that an item does not have a true list price in the system or that something is wrong with the prices on an object.
 *
 * Though this allows for the math to not cause a crash or error out, this is a HUMAN indicator that pricing will required human intervention to complete
 *
 * This indicator can exist in any pricing field but tends to be in List Price and Standard Cost.
 *
 * https://www.notion.so/dascosales/What-is-with-9999-99-price-1f1c5b3507044d6ebbf8778704b2a933
 */
export const priceDefault = 9999.99

export const triggers = [9999.99, 99999.99, 99999]
