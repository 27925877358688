import { useSentry } from '../composables/sentry'
import { ItemType } from '../constants/itemTypes'
import { priceDefault } from '../constants/priceDefault'
import type { ResponseSimple } from '../interfaces/Response'
import { parseDecimal } from './PricingServiceParseDecimal'

const s = useSentry()

interface Payload {
  item: any
}

export const calculateItemSellPriceNonConfigured = (payload: Payload): ResponseSimple => {
  const {
    item
  } = payload

  try {
    if (!item || !item?.qty)
      throw new Error('Error: Item and quantity required')

    if ((!item.sell_price && item.sell_price !== 0) || (item.sell_price === priceDefault && item.type !== ItemType.ItemFromBusinessCentral)) {
      return {
        success: true,
        data: {
          single: priceDefault,
          subtotal: priceDefault,
        }
      }
    }

    const sellPrice = item?.price_override ? +item?.price_override_value : item.sell_price

    return {
      success: true,
      data: {
        single: sellPrice !== priceDefault ? parseDecimal(sellPrice) : priceDefault,
        subtotal: (item.qty && sellPrice !== priceDefault) ? parseDecimal(parseDecimal(sellPrice) * item.qty) : priceDefault
      }
    }
  }
  catch (error: any) {
    s.handleError(error)
    return {
      success: false,
      data: {
        single: priceDefault,
        subtotal: priceDefault
      }
    }
  }
}
