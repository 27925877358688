import { useSentry } from '../composables/sentry'
import { priceDefault } from '../constants/priceDefault'

const s = useSentry()

/**
 * @param {any} payload
 * @param {any} payload.option object: this can be any required option, range, addon option, etc
 * @param {number} payload.multiplier
 */
export const getOptionPrice = (payload: any) => {
  const {
    option,
    multiplier = 1
  } = payload
  // A list price of 0 is the most common default
  if (!option)
    return priceDefault
  try {
    if (typeof +option.list_price !== 'number')
      return priceDefault
    const list_price = +option.list_price
    if (list_price === 0)
      return list_price
    if (!list_price && list_price !== 0)
      return priceDefault
    if (option.net_adder && list_price !== 0)
      return list_price
    if (list_price !== 0)
      return list_price * multiplier

    // throw error if none of these return
    throw new Error(`Issue with option ${option.code} :: ${option.list_price}`)
  }
  catch (error: any) {
    s.handleError(error)
    return priceDefault
  }
}
