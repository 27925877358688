import { useSentry } from '../composables/sentry'

const s = useSentry()

export const getListPriceFixedBase = (payload: any) => {
  const {
    item
  } = payload
  try {
    // Return if it is not a fixed list price item
    if (!item.list_prices_has_fixed)
      return null

    // Return if the fixed list price is already on the item
    if (item?.list_price_fixed_base)
      return item.list_price_fixed_base

    // Return if there are not any fixed list prices to lookup or there is not a desc
    if (!item.base_item)
      return null

    return item?.list_prices_fixed[item.base_item] || null
  }
  catch (error: any) {
    s.handleError(error)
    // console.error(error)
    return null
  }
}
