/**
 * Top level for Pricing Service
 * Functionality should live in other files
 */
// REMOVE for customer facing
import { calculateItemCost } from './PricingServiceCalculateItemCost'

import { calculateItemListPrice } from './PricingServiceCalculateItemListPrice'
import { calculateItemSellPrice } from './PricingServiceCalculateItemSellPrice'
import { calculateDocPricing } from './PricingServiceCalculateDocPricing'
import { getPricingBracketFromDatabaseGetAll } from './PricingServiceGetPricingBracketFromDatabase'
import { getPcPgKeys } from './PricingServiceGetPcPgKeys'
import { getCostMultipliers } from './PricingServiceGetCostMultipliers'
import { getListPriceFixedBase } from './PricingServiceGetListPriceFixedBase'
import { buildMcePriceBrackets } from './PricingServiceBuildMcePriceBrackets'

const PricingService = {
  buildMcePriceBrackets,
  calculateItemCost,
  calculateItemListPrice,
  calculateItemSellPrice,
  calculateDocPricing,
  getCostMultipliers,
  getListPriceFixedBase,
  getPcPgKeys,
  getPricingBracketFromDatabaseGetAll
}

export default PricingService
