const storeToUse = 'quoteEdit'
export default [
  {
    path: '/quotes-edit',
    redirect: { name: 'QuotesList' }
  },
  {
    path: '/quotes-edit/:doc_no',
    name: 'QuoteSingleEdit',
    component: () => import('../views/QuoteSingleEdit.vue'),
    redirect: { name: 'QuoteSingleEditDetails' },
    children: [
      {
        path: 'quote-details',
        name: 'QuoteSingleEditDetails',
        component: () => import('../components/QuoteSingleEditDetails.vue')
      },
      {
        path: 'history',
        name: 'QuoteSingleEditHistory',
        component: () => import('../components/DocSingleHistory.vue'),
        props: { storeToUse }
      },
      {
        path: 'notes',
        name: 'QuoteSingleEditNotes',
        component: () => import('../components/DocSingleNotes.vue'),
        props: { storeToUse, isPrivate: false }
      },
      {
        path: 'notes-internal',
        name: 'QuoteSingleEditNotesInternal',
        component: () => import('../components/DocSingleNotes.vue'),
        props: { storeToUse, isPrivate: true }
      },
      {
        path: 'raw-data',
        name: 'QuoteSingleEditRawData',
        component: () => import('../components/DocSingleRawData.vue'),
        props: { storeToUse }
      }
    ]
  }
]
