interface MultiPropFilter {
  itemsArr: Array<any>
  propsArr: Array<string>
  query: string | null
}

/**
 * @param {MultiPropFilter} payload
 * @param {Array<any>} payload.itemsArr
 * @param {Array<string>} payload.propsArr
 * @param {query} payload.query
 * @return {Array<any>}
 */
export const multiPropFilter = (payload: MultiPropFilter) => {
  const {
    itemsArr,
    propsArr,
    query = ''
  } = payload
  if (!query)
    return itemsArr
  const search = query.toLowerCase().split(' ')
  return itemsArr.filter((i: any) => {
    let searchString = ''

    propsArr.forEach((p: any, index: number) => {
      if (i[p]) {
        const x = i[p].toString().toLowerCase()
        index === 0 ? searchString = x : searchString = `${searchString} ${x}`
      }
    })

    return search.every((o: any) => searchString.includes(o))
  })
}
