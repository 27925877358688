<script setup>
import ScrollTop from 'primevue/scrolltop'
</script>

<template>
  <router-view />
  <scroll-top class="bg-red-500">
    <template #icon>
      <fa-icon
        icon="fas fa-chevron-up"
        class="text-white fa-xl"
      />
    </template>
  </scroll-top>
</template>
