import { useSentry } from '../composables/sentry'
import { tmp_historyEvent } from '../utils/LoggingProps'
import FirestoreService from './FirestoreService'

const s = useSentry()

interface HistoryDetails {
  name?: string
  email?: string
  value: string
}

export interface HistoryEvent {
  id?: string
  created_date: any
  created_name: string
  created_email: string
  created_uid: string
  desc: string
  details?: HistoryDetails
  type: string
}

export const toHistoryEvent = (event: any): HistoryEvent => {
  const obj = {
    ...tmp_historyEvent(),
    desc: event.desc,
    type: event.type
  }

  if (event.details)
    obj.details = event.details

  return obj
}

const HistoryService = {
  /**
   * getHistory() has a default limit of 100 docs
   */
  getHistory: async (payload: any) => {
    const {
      id = null,
      collection = null
    } = payload
    if (!id || !collection)
      throw new Error('Collection and ID are required')
    let history: Array<HistoryEvent> = []
    try {
      const res = await FirestoreService.getDocs({
        collectionPath: `${collection}/${id}/history`
      })
      if (!res.success)
        throw new Error('Error retrieving history docs')
      if (res.exists) {
        history = res.data
          .map((i: any) => i.events)
          .flat()
          .sort((a: any, b: any) => b.created_date.seconds - a.created_date.seconds)
      }
      return {
        success: true,
        data: history
      }
    }
    catch (error: any) {
      s.handleError(error)
      return {
        success: false,
        data: []
      }
    }
  }
}

export default HistoryService
