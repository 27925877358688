<template>
  <div
    class="flex align-items-center flex-shrink-0"
    style="height:60px"
  >
    <img
      alt="Daughtridge Sales logo"
      src="@/assets/Daughtridge-Sales-Color-NoSpacing.svg"
      height="30"
    >
  </div>
</template>
