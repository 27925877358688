import type { serverTimestamp } from 'firebase/firestore'
import type { ObjectKeys } from '../interfaces/ObjectKeys'

// TODO move to package
import { statusUser } from '../constants/statusConstants'

export interface UserSimple extends ObjectKeys {
  uid: string
  account_primary: string
  accountName: string
  accountNo: string
  associated_accounts: Array<string>
  email: string
  firstName: string
  lastName: string
  primaryPhone: string
  primaryPhoneExt: string
  primaryPhoneType: 'Direct' | 'Mobile'
  // accountName: string // deprecate?
  title: string
  roles: string[]
  roles_additional: string[]
  status: string
  slack_user_id?: string
}

export interface User extends UserSimple {
  accountSignUpPhone: string
  active_cart: string
  addressAddress1: string
  addressAddress2: string
  addressAddress3: string
  addressCity: string
  addressCompanyName: string
  addressPostalCode: string
  addressState: string
  created_date: typeof serverTimestamp
  created_name: string
  created_email: string
  created_uid: string
  created_source: string
  customerClass: string
  // carts: Array<any>
  employee: boolean
  firstName: string
  lastName: string
  last_modified_date: typeof serverTimestamp
  last_modified_name: string
  last_modified_email: string
  last_modified_uid: string
  notes_count: number
  notes_internal_count: number
  primaryPhone: string
  primaryPhoneExt: string
  primaryPhoneType: 'Direct' | 'Mobile'
  roles: string[]
  roles_additional: string[]
  // orders: any[] // deprecate?
  secondaryPhone?: string
  secondaryPhoneExt?: string
  secondaryPhoneType?: 'Direct' | 'Mobile'
  slack_user_id?: string
  territoryCode?: string
  title: string
  status: string
  uid: string
  verified: boolean
}

export const toUser = (i: any = {}): User => {
  return {
    account_primary: i.account_primary || null,
    accountName: i.accountName || null,
    accountNo: i.accountNo || null,
    accountSignUpPhone: i.accountSignUpPhone || null,
    active_cart: i.active_cart || null,
    addressAddress1: i.addressAddress1 || null,
    addressAddress2: i.addressAddress2 || null,
    addressAddress3: i.addressAddress3 || null,
    addressCity: i.addressCity || null,
    addressCompanyName: i.addressCompanyName || null,
    addressPostalCode: i.addressPostalCode || null,
    addressState: i.addressState || null,
    associated_accounts: i.associated_accounts || [],
    // carts: i.carts || [],
    created_date: i.created_date || null,
    created_name: i.created_name || null,
    created_email: i.created_email || null,
    created_uid: i.created_uid || null,
    created_source: i.created_source || null,
    customerClass: i.customerClass || null,
    email: i.email || null,
    emailVerified: i.emailVerified || false,
    employee: i.employee || false,
    firstName: i.firstName || null,
    lastName: i.lastName || null,
    last_modified_date: i.last_modified_date || null,
    last_modified_name: i.last_modified_name || null,
    last_modified_email: i.last_modified_email || null,
    last_modified_uid: i.last_modified_uid || null,
    notes_count: i.notes_count || 0,
    notes_internal_count: i.notes_internal_count || 0,
    primaryPhone: i.primaryPhone || null,
    primaryPhoneExt: i.primaryPhoneExt || null,
    primaryPhoneType: i.primaryPhoneType || 'Direct',
    roles: i.roles || [],
    roles_additional: i.roles_additional || [],
    // orders: i.orders || [],
    secondaryPhone: i.secondaryPhone || null,
    secondaryPhoneExt: i.secondaryPhoneExt || null,
    secondaryPhoneType: i.secondaryPhoneType || 'Direct',
    slack_user_id: i.slack_user_id || null,
    status: i.status || statusUser.pending.label,
    territoryCode: i.territoryCode || null,
    title: i.title || null,
    uid: i.uid || null,
    verified: i.verified || false
  }
}

/**
 * For creating/reading users with minimal data mapping
 */
export const toUserSimple = (i: any = {}): UserSimple => {
  return {
    account_primary: i.account_primary || null,
    accountName: i.accountName || null,
    accountNo: i.accountNo || null,
    associated_accounts: i.associated_accounts || [],
    email: i.email,
    firstName: i.firstName || null,
    lastName: i.lastName || null,
    primaryPhone: i.primaryPhone || null,
    primaryPhoneExt: i.primaryPhoneExt || null,
    primaryPhoneType: i.primaryPhoneType || 'Direct',
    roles: i.roles || [],
    roles_additional: i.roles_additional || [],
    slack_user_id: i.slack_user_id || null,
    status: i.status || statusUser.pending.label,
    title: i.title || null,
    uid: i.uid || null
  }
}
