export default [
  {
    path: '/admin/permissions',
    name: 'admin-permissions',
    component: () => import('../views/AdminPermissions.vue'),
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/price-changes',
    name: 'admin-price-changes',
    component: () => import('../views/AdminPriceChanges.vue'),
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/price-changes/bulk-price-changes',
    name: 'admin-price-changes-bulk',
    component: () => import('../views/AdminPriceChangesBulk.vue'),
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/price-changes/scheduled-price-changes',
    name: 'admin-price-changes-scheduled',
    component: () => import('../views/AdminPriceChangesScheduled.vue'),
    meta: { requiresAdmin: true }
  },
  // {
  //   path: '/admin/price-changes/update-configurations',
  //   name: 'admin-price-update-configurations',
  //   component: () => import('../views/AdminPriceChangesUpdateConfigurations.vue'),
  //   meta: { requiresAdmin: true }
  // },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: { requiresAdmin: true },
    children: [
      {
        path: 'price-brackets',
        name: 'price-brackets',
        component: () => import('../components/SettingsPriceBrackets.vue'),
        meta: { requiresAdmin: true }
      },
      {
        path: 'vendors',
        name: 'vendors',
        component: () => import('../components/SettingsVendors.vue'),
        meta: { requiresAdmin: true }
      },
      {
        path: 'standard-notes',
        name: 'standard-notes',
        component: () => import('../components/SettingsStandardNotes.vue'),
        meta: { requiresAdmin: true }
      },
    ]
  }
]
