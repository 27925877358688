<script setup lang="ts">
/**
 * Label Colors examples
 * rgba(0, 0, 0, 0.4) // gray
 * rgba(255, 255, 255, 0.85) // white
 */
interface Props {
  bgColor?: string
  label?: string
  labelColor?: string
  overlay?: 'contain' | 'full'
}
const props = withDefaults(defineProps<Props>(), {
  label: 'Loading',
  overlay: 'contain',
  labelColor: 'text-primary',
  bgColor: 'rgba(255, 255, 255, 0.85)'
})
</script>

<template>
  <div v-if="props.overlay === 'full'">
    <div
      class="d-loader-overlay flex align-items-center justify-items-center"
      :style="`background-color: ${props.bgColor}`"
    >
      <div class="w-6 mx-auto text-center">
        <h1 :class="`text-6xl font-light ${props.labelColor}`" v-html="props.label" />
        <div class="loader" />
      </div>
    </div>
  </div>
  <div
    v-else
    class="w-6 mx-auto text-center"
  >
    <h1 class="text-6xl font-light text-gray-500" v-html="props.label" />
    <div class="loader" />
  </div>
</template>

<style>
.d-loader-overlay {
 position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 19;
  transition-duration: 0.2s;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 3rem auto;
  font-size: .75rem;
  position: relative;
  text-indent: -9999em;
  /* border-top: 1.1em solid rgba(255, 255, 255, 0.5);
  border-right: 1.1em solid rgba(255, 255, 255, 0.5);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.5);
  border-left: 1.1em solid #BDBDBD; */
  border-top: .75em solid rgba(255, 255, 255, 0.5);
  border-right: .75em solid rgba(255, 255, 255, 0.5);
  border-bottom: .75em solid rgba(255, 255, 255, 0.5);
  border-left: .75em solid #BDBDBD;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.4s infinite linear;
  animation: load8 1.3s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
