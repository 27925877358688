import { useSentry } from '../composables/sentry'
import { priceDefault } from '../constants/priceDefault'
import type { ResponseSimple } from '../interfaces/Response'
import { checkContactForPricing } from './PricingServiceCheckContactForPricing'
import { getPricingBracketMultiplier } from './PricingServiceGetPricingBracketMultiplier'
import { sumPriceTotal } from './PricingServiceSumPriceTotal'
import { parseDecimal } from './PricingServiceParseDecimal'

const s = useSentry()

interface Payload {
  item: any
  pricingBracket: any
  qty: number
}

export const calculateItemSellPrice = (payload: Payload): ResponseSimple => {
  const {
    item,
    pricingBracket,
    qty = 1 // single configurator OR TODO pcpgTotal from quote
  } = payload

  try {
    if (!item)
      throw new Error('Error: Item required')
    const contact = checkContactForPricing({ configuration: item?.configuration })

    if (!item.price_override && (contact || item?.list_price === priceDefault)) {
      return {
        success: true,
        data: {
          single: priceDefault,
          subtotal: priceDefault,
        }
      }
    }

    const multiplier = getPricingBracketMultiplier({ pricingBracket, pcpgTotal: qty })

    const sellPrice = item?.price_override ? +item?.price_override_value : sumPriceTotal({ item, multiplier })

    return {
      success: true,
      data: {
        single: sellPrice !== priceDefault ? parseDecimal(sellPrice) : priceDefault,
        subtotal: (item.qty && sellPrice !== priceDefault) ? parseDecimal(parseDecimal(sellPrice) * item.qty) : priceDefault
      }
    }
  }
  catch (error: any) {
    s.handleError(error)
    return {
      success: false,
      data: {
        single: priceDefault,
        subtotal: priceDefault
      }
    }
  }
}
