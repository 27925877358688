const storeToUse = 'quote'
export default [
  {
    path: '/quotes',
    name: 'QuotesList',
    component: () => import('../views/QuotesList.vue')
  },
  {
    path: '/quotes/:doc_no',
    name: 'QuoteSingle',
    component: () => import('../views/QuoteSingle.vue'),
    redirect: { name: 'QuoteSingleDetails' },
    children: [
      {
        path: 'quote-details',
        name: 'QuoteSingleDetails',
        component: () => import('../components/QuoteSingleDetails.vue')
      },
      {
        path: 'history',
        name: 'QuoteSingleHistory',
        component: () => import('../components/DocSingleHistory.vue'),
        props: { storeToUse }
      },
      {
        path: 'notes',
        name: 'QuoteSingleNotes',
        component: () => import('../components/DocSingleNotes.vue'),
        props: { storeToUse, isPrivate: false }
      },
      {
        path: 'notes-internal',
        name: 'QuoteSingleNotesInternal',
        component: () => import('../components/DocSingleNotes.vue'),
        props: { storeToUse, isPrivate: true }
      },
      {
        path: 'raw-data',
        name: 'QuoteSingleRawData',
        component: () => import('../components/DocSingleRawData.vue'),
        props: { storeToUse }
      }
    ]
  },
  {
    path: '/quotes-follow-up',
    name: 'QuotesFollowUpList',
    component: () => import('../views/QuotesFollowUpList.vue')
  },
  {
    path: '/quotes-follow-up-report',
    name: 'QuotesFollowUpReport',
    component: () => import('../views/QuotesFollowUpReport.vue')
  },
]
