/**
 *
 * @param {any} datestamp If coming from Firebase then multiply by 1000
 * @return {string} Date string formatted `yyyy-mm-dd`
 */
export const dateToYYYYMMDD = (datestamp = Date.now()): string => {
  const d = new Date(datestamp)

  // Setup Date
  let dateStr = `${d.getFullYear()}`
  const month = d.getMonth() + 1
  month < 10
    ? dateStr = `${dateStr}-0${month}`
    : dateStr = `${dateStr}-${month}`

  const date = d.getDate()
  date < 10
    ? dateStr = `${dateStr}-0${date}`
    : dateStr = `${dateStr}-${date}`

  return dateStr
}

/**
 *
 * @param {number} s `seconds` from Firestore Date Object
 * @param {null|Date|time} type Type of date/time to return
 * @return {string} Date/Time as local string
 */
export const simpleFirebaseDate = (s: number, type: string | null = null): string => {
  if (!s)
    return ''
  if (type === 'date')
    return new Date(s * 1000).toLocaleString().substring(0, 9)
  else if (type === 'time')
    return new Date(s * 1000).toLocaleString().substring(10).trim()
  else
    return new Date(s * 1000).toLocaleString()
}
