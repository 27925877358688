import { useSentry } from '../composables/sentry'
import type { ResponseSimple } from '../interfaces/Response'
import FirestoreService from './FirestoreService'

const s = useSentry()

const setFromQtyOnBracket = (brackets: any) => {
  let from = 1
  brackets.forEach((i: any) => {
    i.from = from
    from = i.qty + 1
  })
  return brackets
}

/**
 *  DO NOT USE FOR CUSTOMER FACING CLIENT
 *  In Future pass customer class for customers
 * @return {ResponseSimple}
 */
export const getPricingBracketFromDatabaseGetAll = async (): Promise<ResponseSimple> => {
  try {
    const res = await FirestoreService.getDocsByMultipleQueries({
      collection: 'settings/pricing/pricing_brackets',
      conditions: [
        // { prop: 'customerClass', operator: '==', value: '99' }
      ]
    })

    if (!res.success)
      throw new Error('Error retrieving pricing bracket')

    const p: any = {}
    if (!res.data?.length)
      return p

    res.data.forEach((i: any) => {
      const key = `${i.prodClass}${i.prodGroup}`
      if (!Object.hasOwn(p, key)) {
        p[key] = {
          key,
          desc: i.desc
        }
      }

      p[key][i.customerClass] = {
        brackets: setFromQtyOnBracket(i.brackets),
      }
    })

    return {
      success: true,
      data: p
    }
  }
  catch (error: any) {
    s.handleError(error)
    return {
      success: false
    }
  }
}
