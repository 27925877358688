import { getPcPgKey } from '../utils/getPcPgKey'

export const getPcPgKeys = (payload: any): Array<string> => {
  const {
    items
  } = payload
  const pcpgKeys: Array<string> = []
  const arr = [
    ...items.map((i: any) => {
      return {
        pc: i.product_class_code,
        pg: i.product_group_code
      }
    })
  ]
  if (!arr.length)
    return []
  arr.forEach((i: any) => pcpgKeys.push(getPcPgKey(i.pc, i.pg)))
  return [...new Set(pcpgKeys)]
}
