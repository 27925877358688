import { defineStore } from 'pinia'
import _set from 'lodash-es/set'
import { name as appName, version as appVersion } from '../../package.json'
import { useSentry } from '../composables/sentry'
import type { ObjectKeys } from '../interfaces/ObjectKeys'

const s = useSentry()

interface ActiveQuote {
  id: string | null
  doc_no?: string | null
  account_name?: string | null
  account_no?: string | null
  user_name?: string | null
  user_uid?: string | null
}

interface AppStore extends ObjectKeys {
  application: string
  environment: string
  version: string
  preferences: any // for now
  activeQuote: ActiveQuote
}

export const useAppStore = defineStore('AppStore', {
  state: (): AppStore => ({
    application: appName,
    activeQuote: {
      id: null,
      doc_no: null,
      account_name: null,
      account_no: null,
      user_name: null,
      user_uid: null
    },
    environment: import.meta.env.VITE_APPLICATION_ENV,
    version: appVersion,
    preferences: {
      lists: {
        default: {
          hideFacetsWithoutResults: true
        },
        configurators: {
          hideFacetsWithoutResults: false
        },
        items: {
          hideFacetsWithoutResults: false
        },
        thermowells: {
          hideFacetsWithoutResults: false
        },
        users: {
          hideFacetsWithoutResults: false
        }
      },
      configurators: {
        layout: 'list'
      },
      baseModelsList: {
      },
      configurator: {
        showPricingWhenConfigurationIncomplete: false
      }
    }
  }),
  getters: {
    hasActiveQuote: state => !!state.activeQuote?.doc_no
  },
  actions: {
    setPreference(payload: any) {
      const {
        path,
        value
      } = payload
      try {
        _set(this.preferences, path, value)
      }
      catch (error: any) {
        s.handleError(error)
      }
    },
    /** Set Active Quote or clear */
    setActiveQuote(payload: any = {}) {
      const {
        id = null,
        doc_no = null,
        account_name = null,
        account_no = null,
        user_name = null,
        user_uid = null
      } = payload
      this.activeQuote.id = id
      this.activeQuote.doc_no = doc_no
      this.activeQuote.account_name = account_name
      this.activeQuote.account_no = account_no
      this.activeQuote.user_name = user_name
      this.activeQuote.user_uid = user_uid
    }
  }
})
