/**
 *
 * @param val Amount to parse
 * @param places Decimal places to round to. DEFAULT: 2
 * @returns Amount rounded to nearest decimal required
 * // http://www.saintsatplay.com/blog/2014-08-02-handling-floating-point-numbers-in-javascript
 * Look at moving to big.js or other related library
 */
export const parseDecimal = (val: number | string, places = 2): number => {
  return Math.round(Number.parseFloat((+val * (10 ** places)).toFixed(places))) / (10 ** places)
}
