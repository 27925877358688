interface Payload {
  pricingBracket: any | null
  pcpgTotal: number
  blanketQuote?: boolean
}

/**
 * @param {Payload} payload
 * @param {any} payload.pricingBracket: object of pricing bracket data
 * @param {number} payload.pcpgTotal: total quantity of items in this PCPG - This can be from a single line or across all line items
 * @param {boolean} payload.blanketQuote
 * @return {number}
 */

export const getPricingBracketMultiplier = (payload: Payload) => {
  const {
    pricingBracket = null,
    pcpgTotal = 1,
    blanketQuote = false
  } = payload
  // No discount
  let discount = 0
  if (!pricingBracket)
    return 1

  // Determine quantity break
  if (blanketQuote && pricingBracket?.brackets?.length) {
    discount = pricingBracket.brackets[0].discount
  }
  else if (pricingBracket?.brackets?.length) {
    for (const i of pricingBracket.brackets) {
      if (pcpgTotal >= i.from && pcpgTotal <= i.qty)
        discount = i.discount
    }
  }
  // Calculate multiplier for discount
  return discount === 0 ? 1 : 1 - discount
}
