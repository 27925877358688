export default [
  {
    path: '/items',
    name: 'ItemsList',
    component: () => import('../views/ItemsList.vue')
  },
  {
    path: '/items/:id',
    name: 'ItemSingle',
    component: () => import('../views/ItemSingle.vue')
  }
]
