export enum HistoryEventDescriptions {
  archiveSnapshot = 'An archived snapshot of this document was created',
  archiveAndSave = 'An archived snapshot was created and then changes to the doc were saved.',
  associationCreated = 'An association between this doc and another was created',
  associationRemoved = 'An association between this doc and another was removed',
  changesMadeToDoc = 'Changes have been made to the document',
  checkoutDoc = 'This document was checked out for editing',
  checkinDoc = 'This document was checked back in as available for editing',
  createdItem = 'A new item has been created.',
  createdNewAccount = 'This account was created',
  createdNewDoc = 'A new document has been created',
  createdNewUser = 'This user was created',
  createdQuote = 'A new Quote has been created',
  duplicatedQuote = 'This quote was created as a duplication from another document.',
  updatedUserRoles = 'A user\'s roles have been updated.'
}

/**
 * History Event Types
 * Types need to exist in both enums
 */

export enum HistoryEventTypeKeys {
  created = 'created',
  modified = 'modified',
  checkoutDoc = 'checkoutDoc',
  checkinDoc = 'checkinDoc',
  freezeDoc = 'freezeDoc',
  unfreezeDoc = 'unfreezeDoc',
  archiveSnapshot = 'archiveSnapshot',
  archiveAndSave = 'archiveAndSave',
  noteCreated = 'noteCreated',
  createItem = 'createItem'
}

export enum HistoryEventTypes {
  created = 'Document Created',
  modified = 'Document Modified',
  checkoutDoc = 'Document Locked',
  checkinDoc = 'Document Unlocked',
  freezeDoc = 'Document Frozen',
  unfreezeDoc = 'Document Unfrozen',
  archiveSnapshot = 'Document Archived',
  archiveAndSave = 'Document Archived then Saved',
  noteCreated = 'Note Created',
  createItem = 'Item Created'
}

/**
 * Event Icons
 * Uses FontAwesome
 */

export const HistoryEventTypeIcons = {
  archiveSnapshot: 'fa fa-archive',
  archiveAndSave: 'fa fa-archive',
  checkoutDoc: 'fa fa-file-lock',
  checkinDoc: 'fa fa-unlock',
  created: 'fa fa-circle-plus',
  createItem: 'fa fa-gauge-circle-plus',
  freezeDoc: 'fa fa-file-lock',
  modified: 'fa fa-pen-to-square',
  noteCreated: 'fa fa-message-plus',
  priceChange: 'fa fa-circle-dollar',
  unfreezeDoc: 'fa fa-unlock'
}
