export enum AdditionalRole {
  AccessBiReports = 'access-bi-reports',
  AccountsFinanceSuper = 'accts-finance-super',
  AccountsFinanceReadUpdate = 'accts-finance-ru',
  CanOverridePrice = 'can-override-price',
  CanOverrideItemDescription = 'can-override-item-description',
  CanProcessPayment = 'can-process-payment',
  CanProcessRefund = 'can-process-refund',
  CanUnlockQuote = 'can-unlock-quote',
  DevTeam = 'dev-team',
  OpenOrderCanArchive = 'open-order-can-archive'
}

export const additionalRoles: any[] = [
  {
    label: 'Accounts Finance Super',
    code: AdditionalRole.AccountsFinanceSuper,
    blockers: [],
    requires: ['employee'],
    desc: 'This role is to be added to user who has unrestricted access for any financial functionality of the application.'
  },
  {
    label: 'Accounts Finance Read & Write',
    code: AdditionalRole.AccountsFinanceReadUpdate,
    blockers: [],
    requires: ['employee'],
    desc: 'This role allows a user who works in Finance to read and write data to documents that impact functionality. They cannot delete docs'
  },
  {
    label: 'Pricing - Can Override Price',
    code: AdditionalRole.CanOverridePrice,
    blockers: [],
    requires: ['employee'],
    desc: 'This role can be assigned to users who have authority to override pricing of items and configurations. Currently its use is on the sales side of the application. Future use may include purchasing.'
  },
  {
    label: 'Item - Can Override Description',
    code: AdditionalRole.CanOverrideItemDescription,
    blockers: [],
    requires: ['employee'],
    desc: 'This role can be assigned to users who have authority to override item descriptions on configured items.'
  },
  {
    label: 'Dev Team Member',
    code: AdditionalRole.DevTeam,
    blockers: [],
    requires: ['employee'],
    desc: 'This role can be assigned to members of the dev team who need support and maintenance access where this role is used, but not super-user or admin access.'
  },
  {
    label: 'Open Order Archive',
    code: AdditionalRole.OpenOrderCanArchive,
    blockers: [],
    requires: ['employee'],
    desc: 'This role is to allow for archiving of Entities, Orders, or Items in the Open Orders tooling. This role is only needed for archiving. All other update functions are available to employees.'
  },
  {
    code: AdditionalRole.AccessBiReports,
    blockers: [],
    requires: ['employee', 'outside-sales-rep'],
    desc: 'Access Tableau and other BI type reports. Report level access is handled by Tableau',
    label: 'Access BI Reports'
  },
  {
    code: AdditionalRole.CanProcessPayment,
    blockers: [],
    requires: ['employee'],
    desc: 'Can process a Stripe credit card payment.',
    label: 'Can Process Payment'
  },
  {
    code: AdditionalRole.CanProcessRefund,
    blockers: [],
    requires: ['employee'],
    desc: 'Can refund a Stripe credit card payment',
    label: 'Can Process Refund'
  },
  {
    code: AdditionalRole.CanUnlockQuote,
    blockers: [],
    requires: ['employee'],
    desc: 'Can unlock a quote locked by another user',
    label: 'Can Unlock Quote'
  }
]
