<script setup lang="ts">
import {
  ref,
  watch
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { onClickOutside, useMagicKeys } from '@vueuse/core'
import ToastRebuildItemInConfigurator from './components/ToastRebuildItemInConfigurator.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppTopBar from '@/components/AppTopBar.vue'
import AppCommandMenu from '@/components/AppCommandMenu.vue'

/**
 * Quick Navigation with F* keys
 */
const showMenu = ref(false)
const menuRef = ref(null)
const route = useRoute()
const keys = useMagicKeys()
const router = useRouter()

// Show / Hide Menu
// const pressF1 = keys.F1
// watch(pressF1, (v) => {
//   if (v && !showMenu.value)
//     showMenu.value = true
//   else if (v && !!showMenu.value)
//     showMenu.value = false
// })

const pressF2 = keys.F2
watch(pressF2, (v) => {
  if (v && !showMenu.value)
    showMenu.value = true
  else if (v && !!showMenu.value)
    showMenu.value = false
})

// Quick Nav to Dashboard
const pressF3 = keys.F3
watch(pressF3, (v) => {
  if (v && route.path !== '') {
    router.push('/')
    showMenu.value = false
  }
})

// Quick Nav to Users
const pressF4 = keys.F4
watch(pressF4, (v) => {
  if (v && route.path !== 'users') {
    router.push('/users')
    showMenu.value = false
  }
})

// Quick Nav to Items
const pressF9 = keys.F9
watch(pressF9, (v) => {
  if (v && route.path !== 'items') {
    router.push('/items')
    showMenu.value = false
  }
})

// Quick Nav to Items
const pressF10 = keys.F10
watch(pressF10, (v) => {
  if (v && route.path !== 'quotes') {
    router.push('/quotes')
    showMenu.value = false
  }
})

onClickOutside(
  menuRef,
  () => {
    showMenu.value = false
  }
)

const toggleMenu = (value: any) => showMenu.value = value
</script>

<template>
  <div class="min-h-screen flex relative static surface-ground layout-wrapper">
    <div class="layout-main-container min-h-screen flex flex-column relative flex-auto">
      <app-top-bar @go-home="router.push('/')">
        <template #commandMenu>
          <app-command-menu
            :show-menu="showMenu"
            @toggle-menu="toggleMenu"
          />
        </template>
      </app-top-bar>
      <div class="lg:px-5 lg:pt-5 flex flex-column flex-auto">
        <p-toast
          position="top-left"
          style="z-index: 20;"
        />
        <toast-rebuild-item-in-configurator />
        <div class="border-1 border-solid surface-border lg:border-round surface-section flex-auto">
          <router-view />
        </div>
      </div>
      <app-footer />
    </div>
  </div>
  <p-confirm-dialog />
</template>

<style lang="scss">
.layout-wrapper {
  .layout-main-container {
    margin-left: 0;
  }
}

// Disabled 2024-01-23 @bmrankin may not need anymore
// .p-dialog-mask.p-component-overlay {
//   z-index: 4 !important;
// }
</style>
