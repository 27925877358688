export default [
  {
    path: '/base-models',
    name: 'BaseModelsList',
    component: () => import('../views/BaseModelsList.vue')
  },
  {
    path: '/base-models/:id',
    name: 'baseModelOverview',
    component: () => import('../views/BaseModelWrapper.vue')
  }
]
