/**
 *
 * @param {string} value typically a user's email
 * @return {string} DiceBear.com url for avatar
 * Docs: https://www.dicebear.com/styles/identicon/#usage
 */
export const userAvatar = (value: string | null) => {
  const baseUrl = 'https://api.dicebear.com/7.x/identicon/svg?seed='
  if (!value)
    return `${baseUrl}generic-daughtridge-sales`
  return `${baseUrl}${value}`
}
