const storeToUse = 'account'

export default [
  {
    path: '/accounts/',
    name: 'AccountsList',
    component: () => import('../views/AccountsList.vue')
  },
  {
    path: '/accounts/:account_no',
    name: 'AccountSingle',
    component: () => import('../views/AccountSingleWrapper.vue'),
    redirect: { name: 'AccountSingleDetails' },
    children: [
      // {
      //   path: '',
      //   name: 'AccountSingleHome',
      //   component: () => import('../components/AccountSingleHome.vue')
      // },
      {
        path: 'account-details',
        name: 'AccountSingleDetails',
        component: () => import('../components/AccountSingleDetails.vue')
      },
      {
        path: 'associated-accounts',
        name: 'AccountSingleAssociatedAccounts',
        component: () => import('../components/AccountSingleAssociatedAccounts.vue')
      },
      {
        path: 'history',
        name: 'AccountSingleHistory',
        component: () => import('../components/DocSingleHistory.vue'),
        props: { storeToUse }
      },
      {
        path: 'notes',
        name: 'AccountSingleNotes',
        component: () => import('../components/DocSingleNotes.vue'),
        props: { storeToUse, isPrivate: false }
      },
      {
        path: 'notes-internal',
        name: 'AccountSingleNotesInternal',
        component: () => import('../components/DocSingleNotes.vue'),
        props: { storeToUse, isPrivate: true }
      },
      {
        path: 'orders',
        name: 'AccountSingleOrders',
        component: () => import('../components/AccountSingleOrders.vue')
      },
      {
        path: 'raw-data',
        name: 'AccountSingleRawData',
        component: () => import('../components/DocSingleRawData.vue'),
        props: { storeToUse }
      },
      {
        path: 'users',
        name: 'AccountSingleUsers',
        component: () => import('../components/AccountSingleUsers.vue')
      }
    ]
  }
]
