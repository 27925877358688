import { httpsCallable } from 'firebase/functions'
import { useSentry } from '../composables/sentry'
import type { ResponseSimple } from '../interfaces/Response'

/**
 *  DO NOT USE FOR CUSTOMER FACING CLIENT
 * @return {any} Cost multiplier
 */

// Setup cloud functions - Handles use of emulator
import { functions } from './FirebaseFunctionsService'

const s = useSentry()

export const getCostMultipliers = async (): Promise<ResponseSimple> => {
  try {
    const getCostMultipliers = httpsCallable(functions, 'items-getCostMultipliers')
    const res: any = await getCostMultipliers()

    if (!res?.data?.success)
      throw (res?.data?.msg || 'Could not retrieve cost multipliers')

    return {
      success: true,
      data: res?.data?.data
    }
  }
  catch (error: any) {
    s.handleError(error)
    return {
      success: false,
      msg: error
    }
  }
}
