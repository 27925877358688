import { Timestamp, serverTimestamp } from 'firebase/firestore'
import { useSentry } from '../composables/sentry'
import { useActiveUserStore } from '../stores/ActiveUserStore'

const s = useSentry()

export enum LogTypes {
  Approval = 'approval',
  Archive = 'archive',
  Canceled = 'canceled',
  Checkout = 'checkout',
  Created = 'created',
  LastModified = 'last_modified',
  LastReviewed = 'last_reviewed',
  Uploaded = 'uploaded'
}

interface Log {
  date: any // typeof serverTimestamp | typeof FieldValue // https://stackoverflow.com/a/58673848
  name: string | null
  email: string | null
  uid: string | null
}

const log = (): Log => {
  const user = useActiveUserStore().user
  return {
    date: serverTimestamp(),
    name: user.firstName || null, // First Name
    email: user.email || null, // Email of user
    uid: user.uid || null// uid of user
  }
}

export const logProps = (propName: string): any => {
  const { date, name, email, uid } = log()
  return {
    [`${propName}_date`]: date,
    [`${propName}_name`]: name,
    [`${propName}_email`]: email,
    [`${propName}_uid`]: uid
  }
}

export const mapLogProps = (propName: string, data: any): any => {
  try {
    return {
      [`${propName}_date`]: data[`${propName}_date`] || null,
      [`${propName}_name`]: data[`${propName}_name`] || null,
      [`${propName}_email`]: data[`${propName}_email`] || null,
      [`${propName}_uid`]: data[`${propName}_uid`] || null
    }
  }
  catch (error: any) {
    s.handleError(error)
  }
}

/**
 * Temporary log workaround until updates are handled by Firebase Function
 * @returns History log object
 */
export const tmp_historyEvent = (): any => {
  const { name, email, uid } = log()
  return {
    created_date: Timestamp.now(),
    created_name: name,
    created_email: email,
    created_uid: uid
  }
}
